import * as yup from 'yup';

export const relayErrorSchema = yup
  .array()
  .of(yup.object({message: yup.string().required()}));

export const error = (e: unknown) => {
  if (relayErrorSchema.isValidSync(e)) {
    const schema = relayErrorSchema.validateSync(e);
    return schema?.map((s) => s?.message).join(',') ?? 'Something went wrong!';
  }
  // eslint-disable-next-line no-console
  console.log('Unable to parse relay error', e);
  return 'Something went wrong!';
};
