import {
  RelayNetworkLayer,
  urlMiddleware,
  authMiddleware,
  errorMiddleware,
  retryMiddleware,
  progressMiddleware,
} from 'react-relay-network-modern/es';
import {Environment, RecordSource, Store, INetwork} from 'relay-runtime';

import {ConfigService} from '@stackworx/react';
import {useAuthContext} from 'contexts/AuthContext';

const SHOW_PROGRESS = false;

type HandleLogoutFn = () => void;
type GetAuthTokenFn = () => Promise<string>;

function createNetworkLayer(
  handleLogout: HandleLogoutFn,
  getAuthTokenFn: GetAuthTokenFn
): INetwork {
  const network = new RelayNetworkLayer(
    [
      /*
      cacheMiddleware({
        size: 100, // max 100 requests
        ttl: 900000, // 15 minutes
      }),
      */
      urlMiddleware({
        url: () => Promise.resolve(`${ConfigService.serverUri}/graphql`),
      }),
      // IS_DEV_ENV ? loggerMiddleware() : null,
      ConfigService.isDev ? errorMiddleware() : null,
      // IS_DEV_ENV ? perfMiddleware() : null,
      retryMiddleware({
        fetchTimeout: 45000,
        retryDelays: [3200, 6400, 12800],
        forceRetry: (cb, delay) => {
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          window.forceRelayRetry = cb;
          // eslint-disable-next-line no-console
          console.log(
            `call \`forceRelayRetry()\` for immediately retry! Or wait ${delay} ms.`
          );
        },
        statusCodes: [500, 503, 504],
      }),
      authMiddleware({
        allowEmptyToken: true,
        token: getAuthTokenFn,
      }),
      SHOW_PROGRESS
        ? progressMiddleware({
            onProgress: (current, total) => {
              // eslint-disable-next-line no-console
              console.log(
                `Downloaded: ${current} B, total: ${
                  total ? total.toString() : '0'
                } B`
              );
            },
          })
        : null,

      (next) => async (req) => {
        req.fetchOpts.headers.Accept = 'application/json';
        req.fetchOpts.headers['Content-Type'] = 'application/json';

        // TODO x-Request-ID
        // req.fetchOpts.headers['X-Request-ID'] = uuid.v4(); // add `X-Request-ID` to request headers
        req.fetchOpts.credentials = 'same-origin'; // allow to send cookies (sending credentials to same domains)

        try {
          return await next(req);
        } catch (ex) {
          // Logout user out if we get a 401
          if (ex.res && ex.res.status === 401) {
            handleLogout();
          } else {
            // Report error
            //TODO
            // bugsnagClient.notify(ex);
          }

          throw ex;
        }
      },
    ],
    {
      noThrow: true,
    }
  );

  return (network as unknown) as INetwork;
}

export function useCreateRelayEnv() {
  const {auth, handleLogout} = useAuthContext();
  const handlerProvider = undefined;

  const network = createNetworkLayer(handleLogout, async () =>
    auth.authenticated ? auth.token : ''
  );
  const source = new RecordSource();
  const relayStore = new Store(source);

  return new Environment({
    handlerProvider,
    network,
    store: relayStore,
  });
}
