import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {useAlertContext} from '@deckmans/web-shared';
import {useMutation} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {DeleteOptionDialogDeleteMutation} from './__generated__/DeleteOptionDialogDeleteMutation.graphql';

interface Props {
  open: boolean;
  setOpen: () => void;
  optionId: string;
  decisionTypeId: string;
}

export function DeleteOptionDialog({
  open,
  setOpen,
  optionId,
  decisionTypeId,
}: Props) {
  const {alert} = useAlertContext();

  const [deleteDecision] = useMutation<DeleteOptionDialogDeleteMutation>(
    graphql`
      mutation DeleteOptionDialogDeleteMutation(
        $input: DecisionOptionDeleteInput!
      ) {
        decisionOptionDelete(input: $input) {
          decisionTypeId
          decision
        }
      }
    `,
    {
      updater(store, {decisionOptionDelete}) {
        if (decisionOptionDelete) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const workingEdge = store.get(decisionOptionDelete.decisionTypeId);
            if (workingEdge) {
              const options = workingEdge.getLinkedRecords('options');

              if (options) {
                const filteredOptions = options.filter((opt) => {
                  return opt.getDataID() !== decisionOptionDelete.decision;
                });

                const parentFilter = filteredOptions.filter((opt) => {
                  return (
                    opt.getValue('parentId') !== decisionOptionDelete.decision
                  );
                });
                workingEdge?.setLinkedRecords(parentFilter, 'options');
              }
            }
          }
        }
      },
    }
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen();
      }}
      fullWidth
    >
      <DialogTitle>
        <Typography>Are you sure you want to delete this activity?</Typography>
      </DialogTitle>
      <DialogActions>
        <Button
          color="secondary"
          fullWidth
          onClick={async () => {
            try {
              const response = await deleteDecision({
                variables: {
                  input: {
                    optionId,
                    decisionTypeId,
                  },
                },
              });

              if (!response) {
                alert('Something went wrong', 'error');
              }
            } catch (error) {
              alert('Something went wrong', 'error');
            }

            setOpen();
          }}
        >
          Yes
        </Button>
        <Button
          fullWidth
          onClick={() => {
            setOpen();
          }}
          variant="text"
          color="secondary"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
