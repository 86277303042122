/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdminProjectionsRecreateResetMutationVariables = {};
export type AdminProjectionsRecreateResetMutationResponse = {
    readonly adminCreateProjections: {
        readonly clientMutationId: string | null;
        readonly projection: {
            readonly " $fragmentRefs": FragmentRefs<"AdminProjectionsRecreate_projection">;
        };
    } | null;
};
export type AdminProjectionsRecreateResetMutation = {
    readonly response: AdminProjectionsRecreateResetMutationResponse;
    readonly variables: AdminProjectionsRecreateResetMutationVariables;
};



/*
mutation AdminProjectionsRecreateResetMutation {
  adminCreateProjections(input: {recreateSchema: true}) {
    clientMutationId
    projection {
      ...AdminProjectionsRecreate_projection
      id
    }
  }
}

fragment AdminProjectionsRecreate_projection on Projection {
  enabled
  counts {
    survey
    loading
    activities
    vesselClients
    vessels
  }
  progress {
    id
    startTime
    endTime
    durationSeconds
    success
    error
    vesselIds
    vesselCount
  }
  queue {
    id
    vesselId
    queueTime
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "input",
    "value": {
      "recreateSchema": true
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminProjectionsRecreateResetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "AdminCreateProjectionsPayload",
        "kind": "LinkedField",
        "name": "adminCreateProjections",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Projection",
            "kind": "LinkedField",
            "name": "projection",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdminProjectionsRecreate_projection"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "adminCreateProjections(input:{\"recreateSchema\":true})"
      }
    ],
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminProjectionsRecreateResetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "AdminCreateProjectionsPayload",
        "kind": "LinkedField",
        "name": "adminCreateProjections",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Projection",
            "kind": "LinkedField",
            "name": "projection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectionCounts",
                "kind": "LinkedField",
                "name": "counts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "survey",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "loading",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activities",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vesselClients",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vessels",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectionProgress",
                "kind": "LinkedField",
                "name": "progress",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "durationSeconds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "error",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vesselIds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vesselCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectionVesselQueue",
                "kind": "LinkedField",
                "name": "queue",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vesselId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "queueTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "adminCreateProjections(input:{\"recreateSchema\":true})"
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AdminProjectionsRecreateResetMutation",
    "operationKind": "mutation",
    "text": "mutation AdminProjectionsRecreateResetMutation {\n  adminCreateProjections(input: {recreateSchema: true}) {\n    clientMutationId\n    projection {\n      ...AdminProjectionsRecreate_projection\n      id\n    }\n  }\n}\n\nfragment AdminProjectionsRecreate_projection on Projection {\n  enabled\n  counts {\n    survey\n    loading\n    activities\n    vesselClients\n    vessels\n  }\n  progress {\n    id\n    startTime\n    endTime\n    durationSeconds\n    success\n    error\n    vesselIds\n    vesselCount\n  }\n  queue {\n    id\n    vesselId\n    queueTime\n  }\n}\n"
  }
};
})();
(node as any).hash = '6473c5c8eb360c65c373ef274b9c36c5';
export default node;
