import * as React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useMutation} from 'relay-hooks';
import {CircularProgress} from '@material-ui/core';

const mutation = graphql`
  mutation AdminReloadMutation {
    adminReloadState(input: {}) {
      clientMutationId
    }
  }
`;

export function AdminReload() {
  const [mutate, {error, loading}] = useMutation(mutation);

  React.useEffect(() => {
    mutate({
      variables: {},
    });
  }, [mutate]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return <div>Success!</div>;
}
