import {useMutation} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {MutationConfig} from 'relay-hooks/lib/useMutation';
import {useUserUpdateMutation as useUserUpdateMutationType} from './__generated__/useUserUpdateMutation.graphql';

const fragment = graphql`
  mutation useUserUpdateMutation($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        ...UserRolesTable_user @relay(mask: false)
      }
    }
  }
`;
export const useUserUpdateMutation = (
  config?: MutationConfig<useUserUpdateMutationType>
) => useMutation<useUserUpdateMutationType>(fragment, config);
