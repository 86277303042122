import React from 'react';
import {Box} from '@material-ui/core';
import _ from 'lodash';
import {DecisionListItem} from './DecisionListItem';

interface Props {
  singleLoadingDecisions: DecTypes[];
}

interface DecTypes {
  readonly id: string;
  readonly key: string;
  readonly options: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly freeText: boolean;
    readonly parentId: string | null;
    readonly label: string;
    readonly key: string;
    readonly sequence: number | null;
  }>;
}

export function LogbookBuilderSingleLoading({singleLoadingDecisions}: Props) {
  const singleLoadingDecisionId = singleLoadingDecisions.map((dec) => {
    return dec.id;
  })[0];

  const singleLoadingOptions = React.useMemo(() => {
    let singleLoadingOptions: {
      option: string;
      options: {
        id: string;
        name: string;
        freeText: boolean;
        parentId: string | null;
        label: string;
        key: string;
        sequence: number | null;
      }[];
    }[] = [
      {
        option: '',
        options: [
          {
            id: '',
            name: '',
            freeText: false,
            parentId: '',
            label: '',
            key: '',
            sequence: null,
          },
        ],
      },
    ];

    singleLoadingDecisions.forEach((a) => {
      const opt = a.options;
      const singleLoadingOpts = _.chain(opt)
        .groupBy('parentId')
        .map((value, key) => ({option: key, options: value}))
        .value();

      singleLoadingOptions = singleLoadingOpts;
    });
    return singleLoadingOptions;
  }, [singleLoadingDecisions]);

  let singleLoadingParentOptions: {
    option: string;
    options: {
      id: string;
      name: string;
      freeText: boolean;
      parentId: string | null;
      label: string;
      key: string;
      sequence: number | null;
    }[];
  } = {
    option: '',
    options: [
      {
        id: '',
        name: '',
        freeText: false,
        parentId: '',
        label: '',
        key: '',
        sequence: null,
      },
    ],
  };

  if (singleLoadingOptions) {
    singleLoadingParentOptions = singleLoadingOptions.filter((a) => {
      return a.option === 'null';
    })[0];
  }

  const [selected, setSelected] = React.useState(-1);

  const [selectedActivity, setSelectedActivity] = React.useState<{
    id: string;
    parentId: string | null;
  }>({
    id: '',
    parentId: '',
  });

  const [selectedReason1, setSelectedReason1] = React.useState<{
    id: string;
    parentId: string | null;
  }>({id: '', parentId: ''});
  const [selectedReason1Index, setSelectedReason1Index] = React.useState(-1);

  const [openAddDialog, setOpenAddDialog] = React.useState({
    openActivityAddDialog: false,
    openReason1AddDialog: false,
  });

  const [openUpdateDialog, setOpenUpdateDialog] = React.useState({
    openActivityUpdateDialog: false,
    openReason1UpdateDialog: false,
  });

  const [openDeleteDialog, setDeleteDialog] = React.useState({
    openActivityDelDialog: false,
    openReason1DelDialog: false,
  });

  const singleLoadingChildOptions = singleLoadingOptions.filter((a) => {
    return a.option === selectedActivity.id;
  })[0];

  return (
    <>
      <Box display="flex" width="100%" justifyContent="space-between">
        <DecisionListItem
          selectedIndex={selected}
          setSelectedIndex={(val: number) => {
            setSelected(val);
            setSelectedReason1({id: '', parentId: ''});
            setSelectedReason1Index(-1);
          }}
          decisionId={singleLoadingDecisionId}
          subheaderText="Activity"
          decisionOptions={singleLoadingParentOptions}
          openAddDialog={openAddDialog.openActivityAddDialog}
          openDeleteDialog={openDeleteDialog.openActivityDelDialog}
          openUpdateDialog={openUpdateDialog.openActivityUpdateDialog}
          setAddDialog={() => {
            setOpenAddDialog({
              openActivityAddDialog: !openAddDialog.openActivityAddDialog,
              openReason1AddDialog: false,
            });
          }}
          setDeleteDialog={() => {
            setDeleteDialog({
              openActivityDelDialog: !openDeleteDialog.openActivityDelDialog,
              openReason1DelDialog: false,
            });
          }}
          setUpdateDialog={() => {
            setOpenUpdateDialog({
              openActivityUpdateDialog: !openUpdateDialog.openActivityUpdateDialog,
              openReason1UpdateDialog: false,
            });
          }}
          selectedOption={selectedActivity}
          setSelectedOption={(id, parentId) => {
            setSelectedActivity({id: id, parentId: parentId});
          }}
          selectedParent={selectedActivity.parentId}
        />

        <DecisionListItem
          selectedIndex={selectedReason1Index}
          setSelectedIndex={(val: number) => {
            setSelectedReason1Index(val);
          }}
          decisionId={singleLoadingDecisionId}
          subheaderText="Reason 1"
          decisionOptions={singleLoadingChildOptions}
          openAddDialog={openAddDialog.openReason1AddDialog}
          openDeleteDialog={openDeleteDialog.openReason1DelDialog}
          openUpdateDialog={openUpdateDialog.openReason1UpdateDialog}
          setAddDialog={() => {
            setOpenAddDialog({
              openActivityAddDialog: false,
              openReason1AddDialog: !openAddDialog.openReason1AddDialog,
            });
          }}
          setDeleteDialog={() => {
            setDeleteDialog({
              openActivityDelDialog: false,
              openReason1DelDialog: !openDeleteDialog.openReason1DelDialog,
            });
          }}
          setUpdateDialog={() => {
            setOpenUpdateDialog({
              openActivityUpdateDialog: false,
              openReason1UpdateDialog: !openUpdateDialog.openReason1UpdateDialog,
            });
          }}
          selectedOption={selectedReason1}
          setSelectedOption={(id, parentId) => {
            setSelectedReason1({id: id, parentId: parentId});
          }}
          selectedParent={selectedActivity.id}
          showAddDialog={selected !== -1}
        />
      </Box>
    </>
  );
}
