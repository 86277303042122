import * as React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useMutation, useQuery, useFragment} from 'relay-hooks';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import {Switch, TextField} from 'formik-material-ui';
import {Formik, Field} from 'formik';

import {AdminProjectionsRecreateQuery} from './__generated__/AdminProjectionsRecreateQuery.graphql';
import {AdminProjectionsRecreateMutation} from './__generated__/AdminProjectionsRecreateMutation.graphql';
import {AdminProjectionsRecreateResumeMutation} from './__generated__/AdminProjectionsRecreateResumeMutation.graphql';
import {AdminProjectionsRecreatePauseMutation} from './__generated__/AdminProjectionsRecreatePauseMutation.graphql';
import {AdminProjectionsRecreate_projection$key} from './__generated__/AdminProjectionsRecreate_projection.graphql';
import {CircularProgress, FormControlLabel} from '@material-ui/core';

const query = graphql`
  query AdminProjectionsRecreateQuery {
    viewer {
      projection {
        ...AdminProjectionsRecreate_projection
      }
    }
  }
`;

const fragment = graphql`
  fragment AdminProjectionsRecreate_projection on Projection {
    enabled
    counts {
      survey
      loading
      activities
      vesselClients
      vessels
    }
    progress {
      id
      startTime
      endTime
      durationSeconds
      success
      error
      vesselIds
      vesselCount
    }
    queue {
      id
      vesselId
      queueTime
    }
  }
`;

function Pause() {
  const [mutate, {error, loading}] = useMutation<
    AdminProjectionsRecreatePauseMutation
  >(graphql`
    mutation AdminProjectionsRecreatePauseMutation {
      adminPauseProjections(input: {}) {
        clientMutationId
        projection {
          ...AdminProjectionsRecreate_projection
        }
      }
    }
  `);

  return (
    <Box>
      {error && error.message}
      <Button
        disabled={loading}
        onClick={() => {
          mutate({
            variables: {input: {}},
          });
        }}
      >
        Pause
      </Button>
      {loading && <CircularProgress />}
    </Box>
  );
}

function Reset() {
  const [mutate, {error, loading}] = useMutation<
    AdminProjectionsRecreatePauseMutation
  >(graphql`
    mutation AdminProjectionsRecreateResetMutation {
      adminCreateProjections(input: {recreateSchema: true}) {
        clientMutationId
        projection {
          ...AdminProjectionsRecreate_projection
        }
      }
    }
  `);

  return (
    <Box>
      {error && error.message}
      <Button
        disabled={loading}
        onClick={() => {
          mutate({
            variables: {input: {}},
          });
        }}
      >
        Reset
      </Button>
      {loading && <CircularProgress />}
    </Box>
  );
}

function Resume() {
  const [mutate, {error, loading}] = useMutation<
    AdminProjectionsRecreateResumeMutation
  >(graphql`
    mutation AdminProjectionsRecreateResumeMutation {
      adminResumeProjections(input: {}) {
        clientMutationId
      }
    }
  `);

  return (
    <Box>
      {error && error.message}
      <Button
        disabled={loading}
        onClick={() => {
          mutate({
            variables: {input: {}},
          });
        }}
      >
        Resume
      </Button>
      {loading && <CircularProgress />}
    </Box>
  );
}

function AdminProjectionsRecreateFragment({
  projectionRef,
}: {
  projectionRef: AdminProjectionsRecreate_projection$key;
}) {
  const {counts, enabled, progress, queue} = useFragment(
    fragment,
    projectionRef
  );
  const [mutate, {error: mutateError, loading: mutateLoading}] = useMutation<
    AdminProjectionsRecreateMutation
  >(graphql`
    mutation AdminProjectionsRecreateMutation(
      $input: AdminCreateProjectionsInput!
    ) {
      adminCreateProjections(input: $input) {
        clientMutationId
        projection {
          ...AdminProjectionsRecreate_projection
        }
      }
    }
  `);

  return (
    <div>
      {enabled ? <Pause /> : <Resume />} <br />
      <Reset /> <br />
      {mutateError && <div>{mutateError.message}</div>} <br />
      <Formik
        onSubmit={async (values, {setSubmitting}) => {
          const vesselIds = values.all
            ? null
            : values.vesselIds.split(',').map((i) => i.trim());
          try {
            await mutate({
              variables: {
                input: {
                  recreateSchema: false,
                  vesselIds,
                },
              },
            });
            setSubmitting(false);
          } catch (ex) {
            setSubmitting(false);
            alert(ex.message);
          }
        }}
        initialValues={{vesselIds: '', all: false}}
      >
        {({values, isSubmitting, submitForm}) => (
          <Paper>
            <Box m={1} p={1}>
              <Typography variant="h4">Run Projections</Typography>
              <FormControlLabel
                control={
                  <Field
                    type="checkbox"
                    name="all"
                    component={Switch}
                    label="ALL"
                  />
                }
                label="ALL"
              />

              <Typography>Comma seperated vessel ids</Typography>
              <Field
                label="Vessel Ids"
                name="vesselIds"
                disabled={values.all}
                component={TextField}
              />
              <br />
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
                disabled={isSubmitting || mutateLoading}
              >
                Submit
              </Button>
            </Box>
          </Paper>
        )}
      </Formik>
      <Box>
        <Typography variant="h4">Projection Counts</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Projection</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Vessels
                </TableCell>
                <TableCell align="right">{counts.vessels}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Activities
                </TableCell>
                <TableCell align="right">{counts.activities}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Loading
                </TableCell>
                <TableCell align="right">{counts.loading}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Typography variant="h4">Progress</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Duration (seconds)</TableCell>
                <TableCell>Success</TableCell>
                <TableCell>Error</TableCell>
                <TableCell>Vesssel Ids</TableCell>
                <TableCell>Vessel Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {progress.map((p) => (
                <TableRow key={p.id}>
                  <TableCell>{p.startTime}</TableCell>
                  <TableCell>{p.endTime}</TableCell>
                  <TableCell>{p.durationSeconds}</TableCell>
                  <TableCell>
                    {p.success != null ? 'Success' : 'Failed'}
                  </TableCell>
                  <TableCell>{p.error}</TableCell>
                  <TableCell>{p.vesselIds}</TableCell>
                  <TableCell align="right">{p.vesselCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Typography variant="h4">Queue</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Vessel Id</TableCell>
                <TableCell>Queue Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {queue.map((q) => (
                <TableRow key={q.id}>
                  <TableCell>{q.vesselId}</TableCell>
                  <TableCell>{q.queueTime}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}

export function AdminProjectionsRecreate() {
  const {props, error} = useQuery<AdminProjectionsRecreateQuery>(query, {});

  if (error) {
    return <div>{error.message}</div>;
  }

  if (props && props.viewer) {
    const {
      viewer: {projection},
    } = props;
    return <AdminProjectionsRecreateFragment projectionRef={projection} />;
  }

  return <LinearProgress />;
}
