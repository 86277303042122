/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserUpdateInput = {
    id: string;
    roles?: Array<number> | null;
    clients?: Array<string> | null;
    username?: string | null;
    email: string;
    active: boolean;
    name: string;
    surname: string;
    clientMutationId?: string | null;
};
export type useUserUpdateMutationVariables = {
    input: UserUpdateInput;
};
export type useUserUpdateMutationResponse = {
    readonly userUpdate: {
        readonly user: {
            readonly id: string;
            readonly username: string;
            readonly roles: ReadonlyArray<number>;
            readonly account: {
                readonly locked: boolean;
            };
            readonly clients: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
            }>;
            readonly profile: {
                readonly email: string;
                readonly name: string;
                readonly surname: string;
                readonly active: boolean;
            };
            readonly " $fragmentRefs": FragmentRefs<"UserInfo_user">;
        };
    } | null;
};
export type useUserUpdateMutation = {
    readonly response: useUserUpdateMutationResponse;
    readonly variables: useUserUpdateMutationVariables;
};



/*
mutation useUserUpdateMutation(
  $input: UserUpdateInput!
) {
  userUpdate(input: $input) {
    user {
      id
      username
      roles
      account {
        locked
      }
      clients {
        id
        name
      }
      profile {
        email
        name
        surname
        active
      }
      ...UserInfo_user
    }
  }
}

fragment UserInfo_user on User {
  profile {
    email
  }
  username
  account {
    lastLogin
    lastPasswordChange
    locked
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "UserUpdateInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locked",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ClientName",
  "kind": "LinkedField",
  "name": "clients",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "UserProfile",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "surname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUserUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserUpdatePayload",
        "kind": "LinkedField",
        "name": "userUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccount",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserInfo_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUserUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserUpdatePayload",
        "kind": "LinkedField",
        "name": "userUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserAccount",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastLogin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastPasswordChange",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "useUserUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation useUserUpdateMutation(\n  $input: UserUpdateInput!\n) {\n  userUpdate(input: $input) {\n    user {\n      id\n      username\n      roles\n      account {\n        locked\n      }\n      clients {\n        id\n        name\n      }\n      profile {\n        email\n        name\n        surname\n        active\n      }\n      ...UserInfo_user\n    }\n  }\n}\n\nfragment UserInfo_user on User {\n  profile {\n    email\n  }\n  username\n  account {\n    lastLogin\n    lastPasswordChange\n    locked\n  }\n}\n"
  }
};
})();
(node as any).hash = 'af475e121e542dfff62da28a04fcac95';
export default node;
