/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type EquipmentNameCreateInput = {
    equipmentTypeId: string;
    name: string;
    clientMutationId?: string | null;
};
export type InventoryTableEqNamesAddMutationVariables = {
    input: EquipmentNameCreateInput;
};
export type InventoryTableEqNamesAddMutationResponse = {
    readonly equipmentNameCreate: {
        readonly equipmentTypeId: string;
        readonly equipmentName: {
            readonly id: string;
            readonly name: string;
        };
    } | null;
};
export type InventoryTableEqNamesAddMutation = {
    readonly response: InventoryTableEqNamesAddMutationResponse;
    readonly variables: InventoryTableEqNamesAddMutationVariables;
};



/*
mutation InventoryTableEqNamesAddMutation(
  $input: EquipmentNameCreateInput!
) {
  equipmentNameCreate(input: $input) {
    equipmentTypeId
    equipmentName {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "EquipmentNameCreateInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EquipmentNameCreatePayload",
    "kind": "LinkedField",
    "name": "equipmentNameCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "equipmentTypeId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EquipmentName",
        "kind": "LinkedField",
        "name": "equipmentName",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InventoryTableEqNamesAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InventoryTableEqNamesAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InventoryTableEqNamesAddMutation",
    "operationKind": "mutation",
    "text": "mutation InventoryTableEqNamesAddMutation(\n  $input: EquipmentNameCreateInput!\n) {\n  equipmentNameCreate(input: $input) {\n    equipmentTypeId\n    equipmentName {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e42995e8691691b32542f7d99661b2f8';
export default node;
