import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {ConnectionConfig, usePagination} from 'relay-hooks';
import {useRelayPagination} from 'hooks/useRelayPagination';
import MaterialTable from 'material-table';
import {makeStyles, Paper, TextField} from '@material-ui/core';
import {useMutation} from 'relay-hooks';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {GraphQLTaggedNode} from 'relay-runtime';
import {useAlertContext} from '@deckmans/web-shared';
import {InventoryTableOreTypesDeleteMutation} from './__generated__/InventoryTableOreTypesDeleteMutation.graphql';
import {InventoryTableOreTypesAddMutation} from './__generated__/InventoryTableOreTypesAddMutation.graphql';
import {InventoryTableOreTypesUpdateMutation} from './__generated__/InventoryTableOreTypesUpdateMutation.graphql';
import {InventoryTableOreTypes_oreTypes$key} from './__generated__/InventoryTableOreTypes_oreTypes.graphql';

const useStyles = makeStyles(() => ({
  textFieldFont: {
    fontSize: '13px',
  },
}));

const fragment = graphql`
  fragment InventoryTableOreTypes_oreTypes on Viewer {
    clients(first: $count, after: $cursor)
      @connection(key: "Viewer_oreTypes_clients") {
      edges {
        node {
          id
          name
          oreTypes {
            id
            name
          }
        }
      }
      total
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

interface Props {
  query: GraphQLTaggedNode;
  oreTypeKey: InventoryTableOreTypes_oreTypes$key;
  clientId: string;
}

export function InventoryTableOreTypes({query, oreTypeKey, clientId}: Props) {
  const classes = useStyles();
  const {alert} = useAlertContext();

  const [deleteOreType] = useMutation<InventoryTableOreTypesDeleteMutation>(
    graphql`
      mutation InventoryTableOreTypesDeleteMutation(
        $input: OreTypeDeleteInput!
      ) {
        oreTypeDelete(input: $input) {
          clientId
          id
        }
      }
    `,
    {
      updater(store, {oreTypeDelete}) {
        if (oreTypeDelete) {
          const viewer = store.getRoot().getLinkedRecord('viewer');

          if (viewer) {
            const oreType = store.get(oreTypeDelete.id);

            const workingEdge = store.get(oreTypeDelete.clientId);
            const oreTypes = workingEdge?.getLinkedRecords('oreTypes');

            if (oreType && oreTypes) {
              const oreTypesFiltered = oreTypes?.filter((oreType) => {
                return oreType.getDataID() !== oreTypeDelete.id;
              });
              workingEdge?.setLinkedRecords(oreTypesFiltered, 'oreTypes');
            }
          }
        }
      },
    }
  );

  const [addOreType] = useMutation<InventoryTableOreTypesAddMutation>(
    graphql`
      mutation InventoryTableOreTypesAddMutation($input: OreTypeCreateInput!) {
        oreTypeCreate(input: $input) {
          clientId
          oreTypeName {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {oreTypeCreate}) {
        if (oreTypeCreate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const oreType = store.get(oreTypeCreate.oreTypeName.id);
            const workingEdge = store.get(oreTypeCreate.clientId);
            const oreTypes = workingEdge?.getLinkedRecords('oreTypes');

            if (oreType && oreTypes) {
              oreTypes?.push(oreType);
              workingEdge?.setLinkedRecords(oreTypes, 'oreTypes');
            }
          }
        }
      },
      onCompleted() {
        refetch();
      },
    }
  );

  const [updateOreType] = useMutation<InventoryTableOreTypesUpdateMutation>(
    graphql`
      mutation InventoryTableOreTypesUpdateMutation(
        $input: OreTypeUpdateInput!
      ) {
        oreTypeUpdate(input: $input) {
          oreTypeName {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {oreTypeUpdate}) {
        if (oreTypeUpdate) {
          const payload = store.getRootField('oreTypeUpdate');
          const oreType = payload.getLinkedRecord('oreTypeName');

          const nameOfOreType = oreType.getValue('name');
          const idOfOreType = oreType.getValue('id');

          const newName = store.get(idOfOreType);
          newName?.setValue(nameOfOreType, 'name');
        }
      },
    }
  );

  const connectionConfig: ConnectionConfig = {
    getVariables(_props, {count, cursor}, _fv) {
      return {
        count,
        cursor,
      };
    },
    query,
  };

  const [oreTypes, pf] = usePagination(fragment, oreTypeKey);

  const {pagedData, refetch} = useRelayPagination(
    oreTypes.clients.total ?? 0,
    10,
    pf,
    connectionConfig,
    oreTypes.clients
  );

  const tableData = React.useMemo(() => {
    const tableData: {
      clientId: string;
      id: string;
      name: string;
    }[] = [];

    pagedData.forEach((oreType) => {
      if (oreType) {
        const clientOreTypeId = oreType.id;
        if (clientOreTypeId === clientId) {
          oreType.oreTypes.forEach((ore) => {
            tableData.push({
              clientId: clientOreTypeId,
              id: ore.id,
              name: ore.name,
            });
          });
        }
      }
    });
    return tableData;
  }, [pagedData, clientId]);

  return (
    <>
      <MaterialTable
        data={tableData}
        options={{
          pageSize: 10,
          pageSizeOptions: [],
          headerStyle: {position: 'sticky', top: 0},
          emptyRowsWhenPaging: false,
          showTitle: false,
          actionsColumnIndex: -1,
        }}
        columns={[
          {
            title: 'Ore Type',
            field: 'name',
            validate: function validate(rowData) {
              if (!rowData.name) {
                return false;
              } else {
                if (rowData.name.trim() === '') {
                  return false;
                } else {
                  return true;
                }
              }
            },
            editComponent: function editComp(tableData) {
              const {columnDef, rowData, onRowDataChange, ...props} = tableData;
              return (
                <TextField
                  {...props}
                  value={tableData.rowData.name || ''}
                  inputProps={{autoFocus: true}}
                  placeholder="Name"
                  onChange={(e) => props.onChange(e.target.value)}
                  InputProps={{className: classes.textFieldFont}}
                />
              );
            },
          },
        ]}
        icons={{
          Add: React.forwardRef(function addCircle(props, ref) {
            return <AddCircleIcon color="secondary" {...props} ref={ref} />;
          }),

          Edit: React.forwardRef(function editCircle(props, ref) {
            return <EditRoundedIcon color="primary" {...props} ref={ref} />;
          }),

          Delete: React.forwardRef(function deleteCircle(props, ref) {
            return <DeleteRoundedIcon color="primary" {...props} ref={ref} />;
          }),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await addOreType({
                    variables: {
                      input: {
                        clientId: clientId,
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }

                reject();
              }, 1000);
            }),

          onRowUpdate: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await updateOreType({
                    variables: {
                      input: {
                        id: newData.id,
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                try {
                  const deleteResponse = deleteOreType({
                    variables: {
                      input: {id: oldData.id, clientId: clientId},
                    },
                  });
                  if (deleteResponse) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),
        }}
        components={{
          Container: function containerComp(props) {
            return <Paper {...props} elevation={0} />;
          },
        }}
      />
    </>
  );
}
