import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {RelayRenderer} from 'modules/RelayRenderer/RelayRenderer';

const query = graphql`
  query TestQuery {
    viewer {
      trains {
        edges {
          node {
            id
            name
          }
        }
      }
      stockpiles {
        edges {
          node {
            id
            name
          }
        }
      }
      equipmentTypes {
        edges {
          node {
            id
            name
            equipmentNames {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export function Test() {
  return (
    <RelayRenderer query={query}>
      {(props) => {
        return <>{JSON.stringify(props?.viewer, null, 2)}</>;
      }}
    </RelayRenderer>
  );
}
