/**
 * Hook meant to simplify relay pagination
 */
import * as React from 'react';
import {ConnectionConfig, PaginationFunction} from 'relay-hooks';

interface Connection<Node> {
  edges: ReadonlyArray<
    | {
        readonly node: Node | null;
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | {[key: string]: any}
  > | null;
}

interface Output<T> {
  page: number;
  setPage(p: number): void;
  pagedData: T[];
  loading: boolean;
  reset(): void;
  refetch(): void;
}

export function useRelayPagination<T>(
  totalRows: number,
  pageSize: number,
  relay: PaginationFunction,
  config: ConnectionConfig,
  // Make optional to make refactoring easier
  connection: Connection<T> | null
): Output<T> {
  const largestPageFetched = React.useRef(0);
  // TODO: refetch connection?

  const totalPages = React.useMemo(() => totalRows / pageSize, [
    totalRows,
    pageSize,
  ]);

  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | undefined>(undefined);

  const reset = React.useCallback(() => {
    setPage(0);
    largestPageFetched.current = 0;
    setLoading(false);
    setError(undefined);
  }, [setPage, setLoading, setError]);

  const refetch = React.useCallback(
    (v?, count?: number) => {
      setLoading(true);
      relay.refetchConnection(
        config,
        count ?? 10,
        () => {
          // setLoading(false);
          // setError(error);
        },
        v
      );
    },
    [relay, config]
  );

  const loadMore = React.useCallback(
    (amountToLoad: number) => {
      const result = relay.loadMore(
        config,
        amountToLoad,
        (error) => {
          setLoading(false);
          if (error) {
            setError(error);
          } else {
            largestPageFetched.current = page;
          }
        },
        {}
      );
      return () => {
        if (result) {
          result.dispose();
        }
      };
    },
    [relay, setLoading, page, config]
  );

  React.useEffect(() => {
    if (relay.isLoading()) {
      return;
    }
    // Fetching more
    if (page > largestPageFetched.current) {
      setLoading(true);
      setError(undefined);

      const amountToLoad = (page - largestPageFetched.current) * pageSize;
      loadMore(amountToLoad);
    }
  }, [relay, totalPages, pageSize, setLoading, page, loadMore]);

  const pagedData: T[] = React.useMemo(() => {
    if (!connection) {
      return [];
    }
    const start = page * pageSize;
    const end = (page + 1) * pageSize;

    const response: T[] = [];

    connection?.edges?.forEach((edge) => {
      if (edge?.node) {
        response.push(edge.node);
      }
    });
    return response.slice(start, end);
  }, [connection, page, pageSize]);

  return React.useMemo(
    () => ({page, setPage, loading, error, reset, pagedData, refetch}),
    [page, setPage, loading, pagedData, error, reset, refetch]
  );
}
