/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type EquipmentNameUpdateInput = {
    id: string;
    name: string;
    clientMutationId?: string | null;
};
export type InventoryTableEqNamesUpdateMutationVariables = {
    input: EquipmentNameUpdateInput;
};
export type InventoryTableEqNamesUpdateMutationResponse = {
    readonly equipmentNameUpdate: {
        readonly equipmentName: {
            readonly id: string;
            readonly name: string;
        };
    } | null;
};
export type InventoryTableEqNamesUpdateMutation = {
    readonly response: InventoryTableEqNamesUpdateMutationResponse;
    readonly variables: InventoryTableEqNamesUpdateMutationVariables;
};



/*
mutation InventoryTableEqNamesUpdateMutation(
  $input: EquipmentNameUpdateInput!
) {
  equipmentNameUpdate(input: $input) {
    equipmentName {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "EquipmentNameUpdateInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EquipmentNameUpdatePayload",
    "kind": "LinkedField",
    "name": "equipmentNameUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EquipmentName",
        "kind": "LinkedField",
        "name": "equipmentName",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InventoryTableEqNamesUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InventoryTableEqNamesUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InventoryTableEqNamesUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation InventoryTableEqNamesUpdateMutation(\n  $input: EquipmentNameUpdateInput!\n) {\n  equipmentNameUpdate(input: $input) {\n    equipmentName {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '196417581c02bafe3ccf2bd2767d45ed';
export default node;
