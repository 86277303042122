/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserInfo_user = {
    readonly profile: {
        readonly email: string;
    };
    readonly username: string;
    readonly account: {
        readonly lastLogin: string | null;
        readonly lastPasswordChange: string | null;
        readonly locked: boolean;
    };
    readonly " $refType": "UserInfo_user";
};
export type UserInfo_user$data = UserInfo_user;
export type UserInfo_user$key = {
    readonly " $data"?: UserInfo_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserInfo_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserInfo_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserAccount",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastLogin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastPasswordChange",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locked",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User"
};
(node as any).hash = 'fa47055fcac331c4e9d27fde3afb3a01';
export default node;
