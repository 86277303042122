import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Drawer,
  ButtonBase,
  makeStyles,
  Fab,
  Avatar,
} from '@material-ui/core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ChromeReaderModeOutlinedIcon from '@material-ui/icons/ChromeReaderModeOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import {useTitleContext} from '@deckmans/web-shared';
import logo from '../../images/anglo-logo@1x.png';
import pointerImg from '../../images/pointer.svg';
import {useHistory} from 'react-router';
import {useAuthContext} from 'contexts/AuthContext';
import {Switch, Route} from 'react-router';
import {getUserRole} from '@deckmans/domain/lib/util/enumData';
import useWindowDimensions from 'hooks/useWindowDimensions';

function Header() {
  const {width} = useWindowDimensions();
  const useStyles = makeStyles((theme) => ({
    appBar: {
      backgroundColor: '#f4f6f8',
      //margin: '0px 20px 0px 20px',
      margin: '0px auto',
      maxWidth: width - 100,
      width: 'calc(100% - 50px)',
      [theme.breakpoints.up('md')]: {
        //margin: '20px auto',
        margin: '0px auto',
      },
    },
    toolBar: {
      borderRadius: '4px',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerBox: {
      top: 0,
      position: 'sticky',
      zIndex: 1,
      backgroundColor: '#f4f6f8',
      padding: '20px 0px 20px 0px',
      [theme.breakpoints.up('md')]: {
        padding: '20px auto',
      },
    },
    adminPortalBoxText: {
      position: 'absolute',
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(0.125),
    },
    version: {
      color: '#808080',
    },
  }));

  const classes = useStyles();
  const {title} = useTitleContext();
  const [openMenu, setOpenMenu] = React.useState(false);
  const history = useHistory();
  const {auth, handleLogout} = useAuthContext();
  // const {alert} = useAlertContext();

  return (
    <Box className={classes.headerBox}>
      <AppBar position="relative" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Box display="flex" alignItems="center">
            <Box>
              <Switch>
                <Route
                  exact
                  path={['/', '/home']}
                  component={() => (
                    <Avatar
                      variant="square"
                      src={logo}
                      imgProps={{style: {objectFit: 'contain'}}}
                    />
                  )}
                />
                <Route
                  exact
                  path="/*"
                  component={() => (
                    <Fab
                      size="small"
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      <ArrowBackRoundedIcon />
                    </Fab>
                  )}
                />
              </Switch>
            </Box>
            <Box p={1}>
              <Typography color="primary" variant="h5">
                {title}
              </Typography>
            </Box>
          </Box>

          <Box display="flex">
            <img src={pointerImg} alt="" />
            <Typography
              className={classes.adminPortalBoxText}
              variant="caption"
            >
              Admin Portal
            </Typography>
          </Box>
          <Box display="flex">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              pr={1.5}
            >
              <Typography variant="body2" color="textPrimary">
                {auth.authenticated ? auth.username : 'Display name'}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {auth.authenticated
                  ? auth.roles.map((role) => {
                      return getUserRole(role).description;
                    })
                  : 'Role'}
              </Typography>
            </Box>
            <Fab
              size="small"
              color="primary"
              onClick={() => {
                setOpenMenu(!openMenu);
              }}
            >
              <MenuRoundedIcon />
            </Fab>
          </Box>
        </Toolbar>
        <Drawer
          open={openMenu}
          onClose={() => {
            setOpenMenu(!openMenu);
          }}
          anchor="right"
        >
          <Box pt={3} display="flex" flexDirection="column" width="250px">
            <Box p={2}>
              <ButtonBase
                onClick={async () => {
                  history.push('/logbook-builder');
                  setOpenMenu(!openMenu);
                }}
              >
                <ChromeReaderModeOutlinedIcon
                  style={{paddingRight: '36px'}}
                  color="primary"
                />
                <Typography variant="body1">Logbook Builder</Typography>
              </ButtonBase>
            </Box>
            <Box p={2}>
              <ButtonBase
                onClick={async () => {
                  history.push('/inventory');
                  setOpenMenu(!openMenu);
                }}
              >
                <AssignmentTurnedInOutlinedIcon
                  style={{paddingRight: '36px'}}
                  color="primary"
                />
                <Typography variant="body1">Inventory</Typography>
              </ButtonBase>
            </Box>
            <Box p={2}>
              <ButtonBase
                onClick={async () => {
                  history.push('/user-roles');
                  setOpenMenu(!openMenu);
                }}
              >
                <AssignmentIndOutlinedIcon
                  style={{paddingRight: '36px'}}
                  color="primary"
                />
                <Typography variant="body1">User Management</Typography>
              </ButtonBase>
            </Box>

            <Box p={2}>
              <ButtonBase
                onClick={async () => {
                  handleLogout();
                }}
              >
                <ExitToAppRoundedIcon
                  style={{paddingRight: '36px'}}
                  color="primary"
                />
                <Typography variant="body1">Logout</Typography>
              </ButtonBase>
            </Box>
          </Box>
          <Box
            height="100%"
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
            p={1}
          >
            <Typography className={classes.version} variant="body1">
              {process.env.REACT_APP_CI_VERSION}
            </Typography>
          </Box>
        </Drawer>
      </AppBar>
    </Box>
  );
}

export function HeaderRouter() {
  return (
    <Switch>
      <Route exact path="/login" />
      <Route exact path="/createPassword" />
      <Route exact path="/resetPassword" />
      <Route exact path="/changePassword" />
      <Route exact path="/tokenExpired" />
      <Route>
        <Header />
      </Route>
    </Switch>
  );
}
