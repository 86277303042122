/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DecisionTypeEnum = "DECISION_TYPE_BREAKDOWN_ACTIVITY" | "DECISION_TYPE_HATCH_RECLAIM_ACTIVITY" | "DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY" | "DECISION_TYPE_NON_HATCH_SHIFTING_ACTIVITY" | "DECISION_TYPE_NON_HATCH_TNPA_ACTIVITY" | "DECISION_TYPE_RECLAIM_ACTIVITY" | "DECISION_TYPE_SINGLE_LOADING_ACTIVITY" | "DECISION_TYPE_STOP_VESSEL_ACTIVITY" | "DECISION_TYPE_VESSEL_DELAY_OPTIONS";
export type LogbookBuilderTable_decisions = {
    readonly decisions: ReadonlyArray<{
        readonly id: string;
        readonly key: DecisionTypeEnum;
        readonly options: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly freeText: boolean;
            readonly parentId: string | null;
            readonly label: string;
            readonly key: string;
            readonly sequence: number;
        }>;
    }>;
    readonly " $refType": "LogbookBuilderTable_decisions";
};
export type LogbookBuilderTable_decisions$data = LogbookBuilderTable_decisions;
export type LogbookBuilderTable_decisions$key = {
    readonly " $data"?: LogbookBuilderTable_decisions$data;
    readonly " $fragmentRefs": FragmentRefs<"LogbookBuilderTable_decisions">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LogbookBuilderTable_decisions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Decision",
      "kind": "LinkedField",
      "name": "decisions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "DecisionOption",
          "kind": "LinkedField",
          "name": "options",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "freeText",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "parentId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Master"
};
})();
(node as any).hash = '89f4d42502ce84b388e84e1218e50cf5';
export default node;
