import React from 'react';
import Box from '@material-ui/core/Box';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  OptionsInner,
  UpdateDecisionSequenceDown,
  UpdateDecisionSequenceUp,
} from '../LogbookBuilderHelper';
import {useAlertContext} from '@deckmans/web-shared';
import {useUpdateDecisionSequenceMutation} from '../mutations/UpdateDecisionSequenceMutation';

interface Props {
  index: number;
  array: OptionsInner[];
  decisionId: string;
  setSelected: (val: number) => void;
}

export default function SequenceOrderButtons({
  array,
  decisionId,
  index,
  setSelected,
}: Props) {
  const {alert} = useAlertContext();

  const [updateDecisionSequence] = useUpdateDecisionSequenceMutation();
  return (
    <Box display="flex" pl={2}>
      <ExpandLessIcon
        onClick={() => {
          UpdateDecisionSequenceUp(
            index,
            array,
            decisionId,
            setSelected,
            updateDecisionSequence,
            alert
          );
        }}
      />
      <ExpandMoreIcon
        onClick={() => {
          UpdateDecisionSequenceDown(
            index,
            array,
            decisionId,
            setSelected,
            updateDecisionSequence,
            alert
          );
        }}
      />
    </Box>
  );
}
