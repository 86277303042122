/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type StockpileDeleteInput = {
    id: string;
    clientMutationId?: string | null;
};
export type InventoryTableStockpilesDeleteMutationVariables = {
    input: StockpileDeleteInput;
};
export type InventoryTableStockpilesDeleteMutationResponse = {
    readonly stockpileDelete: {
        readonly id: string;
    } | null;
};
export type InventoryTableStockpilesDeleteMutation = {
    readonly response: InventoryTableStockpilesDeleteMutationResponse;
    readonly variables: InventoryTableStockpilesDeleteMutationVariables;
};



/*
mutation InventoryTableStockpilesDeleteMutation(
  $input: StockpileDeleteInput!
) {
  stockpileDelete(input: $input) {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "StockpileDeleteInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "StockpileDeletePayload",
    "kind": "LinkedField",
    "name": "stockpileDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InventoryTableStockpilesDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InventoryTableStockpilesDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InventoryTableStockpilesDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation InventoryTableStockpilesDeleteMutation(\n  $input: StockpileDeleteInput!\n) {\n  stockpileDelete(input: $input) {\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '287dab50cbf4447eeb22c1d621dfd722';
export default node;
