import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {ConnectionConfig, usePagination} from 'relay-hooks';
import {useRelayPagination} from 'hooks/useRelayPagination';
import MaterialTable from 'material-table';
import {makeStyles, Paper, TablePagination, TextField} from '@material-ui/core';
import {useMutation} from 'relay-hooks';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {ConnectionHandler, GraphQLTaggedNode} from 'relay-runtime';
import {InventoryTableRoutes_route$key} from './__generated__/InventoryTableRoutes_route.graphql';
import {InventoryTableRoutesDeleteMutation} from './__generated__/InventoryTableRoutesDeleteMutation.graphql';
import {InventoryTableRoutesAddMutation} from './__generated__/InventoryTableRoutesAddMutation.graphql';
import {InventoryTableRoutesUpdateMutation} from './__generated__/InventoryTableRoutesUpdateMutation.graphql';
import {useAlertContext} from '@deckmans/web-shared';
import {useWindowSize} from 'react-use';

const useStyles = makeStyles(() => ({
  textFieldFont: {
    fontSize: '13px',
  },
}));

const fragment = graphql`
  fragment InventoryTableRoutes_route on Viewer {
    routes(first: $count, after: $cursor) @connection(key: "Viewer_routes") {
      edges {
        node {
          id
          name
        }
      }
      total
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

interface Props {
  query: GraphQLTaggedNode;
  routeKey: InventoryTableRoutes_route$key;
}

export function InventoryTableRoutes({query, routeKey}: Props) {
  const classes = useStyles();
  const {alert} = useAlertContext();
  const {height} = useWindowSize();

  const [deleteRoute] = useMutation<InventoryTableRoutesDeleteMutation>(
    graphql`
      mutation InventoryTableRoutesDeleteMutation($input: RouteDeleteInput!) {
        routeDelete(input: $input) {
          id
        }
      }
    `,
    {
      updater(store, {routeDelete}) {
        if (routeDelete) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const users = ConnectionHandler.getConnection(
              viewer,
              'Viewer_routes'
            );
            if (users) {
              ConnectionHandler.deleteNode(users, routeDelete.id);
            }
          }
        }
      },
    }
  );

  const [addRoute] = useMutation<InventoryTableRoutesAddMutation>(
    graphql`
      mutation InventoryTableRoutesAddMutation($input: RouteCreateInput!) {
        routeCreate(input: $input) {
          route {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {routeCreate}) {
        if (routeCreate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');

          if (viewer) {
            const routes = ConnectionHandler.getConnection(
              viewer,
              'Viewer_routes'
            );
            if (routes) {
              const route = store.get(routeCreate.route.id);

              if (route) {
                const edge = ConnectionHandler.createEdge(
                  store,
                  routes,
                  route,
                  'RoutesEdge'
                );
                ConnectionHandler.insertEdgeAfter(routes, edge);
              }
            }
          }
        }
      },
    }
  );

  const [updateRoute] = useMutation<InventoryTableRoutesUpdateMutation>(
    graphql`
      mutation InventoryTableRoutesUpdateMutation($input: RouteUpdateInput!) {
        routeUpdate(input: $input) {
          route {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {routeUpdate}) {
        if (routeUpdate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');

          if (viewer) {
            const routes = ConnectionHandler.getConnection(
              viewer,
              'Viewer_routes'
            );
            if (routes) {
              const route = store.get(routeUpdate.route.id);
              if (route) {
                ConnectionHandler.createEdge(
                  store,
                  routes,
                  route,
                  'RoutesEdge'
                );
              }
            }
          }
        }
      },
    }
  );

  const connectionConfig: ConnectionConfig = {
    getVariables(_props, {count, cursor}, _fv) {
      return {
        count,
        cursor,
      };
    },
    query,
  };

  const [route, pf] = usePagination(fragment, routeKey);

  const {page, pagedData, setPage} = useRelayPagination(
    route.routes.total ?? 0,
    10,
    pf,
    connectionConfig,
    route.routes
  );

  const tableData = React.useMemo(() => {
    const tableData: {
      id: string;
      name: string;
    }[] = [];

    pagedData.forEach((route) => {
      if (route) {
        tableData.push({
          id: route.id,
          name: route.name,
        });
      }
    });
    return tableData;
  }, [pagedData]);

  return (
    <>
      <MaterialTable
        data={tableData}
        options={{
          showTitle: false,
          search: false,
          pageSize: 10,
          headerStyle: {position: 'sticky', top: 0},
          maxBodyHeight: height - 300,
        }}
        columns={[
          {
            title: 'Name',
            field: 'name',
            validate: function validate(rowData) {
              if (!rowData.name) {
                return false;
              } else {
                if (rowData.name.trim() === '') {
                  return false;
                } else {
                  return true;
                }
              }
            },
            editComponent: function editComp(tableData) {
              const {columnDef, rowData, onRowDataChange, ...props} = tableData;
              return (
                <TextField
                  {...props}
                  value={tableData.rowData.name || ''}
                  inputProps={{autoFocus: true}}
                  placeholder="Name"
                  onChange={(e) => props.onChange(e.target.value)}
                  InputProps={{className: classes.textFieldFont}}
                />
              );
            },
          },
        ]}
        icons={{
          Add: React.forwardRef(function addCircle(props, ref) {
            return <AddCircleIcon color="secondary" {...props} ref={ref} />;
          }),

          Edit: React.forwardRef(function editCircle(props, ref) {
            return <EditRoundedIcon color="primary" {...props} ref={ref} />;
          }),

          Delete: React.forwardRef(function deleteCircle(props, ref) {
            return <DeleteRoundedIcon color="primary" {...props} ref={ref} />;
          }),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await addRoute({
                    variables: {
                      input: {
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowUpdate: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await updateRoute({
                    variables: {
                      input: {
                        id: newData.id,
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                try {
                  const deleteResponse = deleteRoute({
                    variables: {input: {id: oldData.id}},
                  });
                  if (deleteResponse) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),
        }}
        components={{
          Pagination: function paginationComp(props) {
            return (
              <TablePagination
                {...props}
                rowsPerPageOptions={[]}
                rowsPerPage={10}
                count={route.routes.total ?? 0}
                page={page}
                onChangePage={(_e, page) => {
                  setPage(page);
                }}
              />
            );
          },
          Container: function containerComp(props) {
            return <Paper {...props} elevation={0} />;
          },
        }}
      />
    </>
  );
}
