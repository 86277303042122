import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {createStyles, Fab, Grid, makeStyles, Theme} from '@material-ui/core';
import ChromeReaderModeRoundedIcon from '@material-ui/icons/ChromeReaderModeRounded';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import AssignmentIndRoundedIcon from '@material-ui/icons/AssignmentIndRounded';
import {useHistory} from 'react-router';
import Ship from 'images/ship.svg';
import {useTitle} from '@deckmans/web-shared';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    buttonStyle: {
      backgroundColor: 'white',
      color: theme.palette.text.primary,
      borderRadius: '4px',
      boxShadow: theme.shadows[4],
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
      cursor: 'pointer',
    },
    fabStyle: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    },
  })
);

export function Homepage() {
  const classes = styles();
  const history = useHistory();
  useTitle('Deckmans');

  return (
    <Box display="flex" flexWrap="wrap">
      <Box width="50%">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              className={classes.buttonStyle}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              onClick={() => {
                history.push('/logbook-builder');
              }}
            >
              <Typography variant="h6">Logbook Builder</Typography>
              <Fab color="primary" size="small" className={classes.fabStyle}>
                <ChromeReaderModeRoundedIcon />
              </Fab>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className={classes.buttonStyle}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              onClick={() => {
                history.push('/inventory');
              }}
            >
              <Typography variant="h6">Inventory</Typography>
              <Fab color="primary" size="small" className={classes.fabStyle}>
                <AssignmentTurnedInRoundedIcon />
              </Fab>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className={classes.buttonStyle}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              onClick={() => {
                history.push('/user-roles');
              }}
            >
              <Typography variant="h6">User Management</Typography>
              <Fab color="primary" size="small" className={classes.fabStyle}>
                <AssignmentIndRoundedIcon />
              </Fab>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box width="50%" display="flex" justifyContent="center">
        <img src={Ship} alt="" />
      </Box>
    </Box>
  );
}
