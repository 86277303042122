import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import {createLoginService} from 'services';
import moment from 'moment';
import {useAlertContext} from '@deckmans/web-shared';
import {clearUser, setUser} from 'appInsights';
import * as domain from '@deckmans/domain';
import {useHistory} from 'react-router';

export type AuthContextType =
  | {
      token: string;
      authenticated: true;
      roles: domain.UserRole[];
      username: string;
      userId: number;
      expiry: Date;
    }
  | {
      authenticated: false;
    };

export interface AuthContextStateType {
  auth: AuthContextType;
  handleLogin: (username: string, password: string) => Promise<void>;
  handleLogout: () => void;
  handleCreatePassword: (token: string, password: string) => Promise<void>;
  handleRequestResetPassword: (req: {
    username: string;
    auth?: boolean;
  }) => Promise<domain.ResetPasswordResponse>;
  hasRole(role: domain.UserRole): boolean;
}

export const AuthContext = createContext<AuthContextStateType>({
  auth: {authenticated: false},
  handleLogin: async () => new Promise(() => {}),
  handleCreatePassword: async () => new Promise(() => {}),
  handleRequestResetPassword: async () => new Promise(() => {}),
  handleLogout: () => {},
  hasRole: () => true,
});
interface Props {
  children: JSX.Element;
}
export const AuthContextProvider = React.memo(function AuthContextProvider({
  children,
}: Props) {
  const loginService = createLoginService();
  const loggingOut = useRef(false);
  const authTokenLoaded = React.useRef<boolean>(false);
  const {push: navigate} = useHistory();

  const {alert} = useAlertContext();
  const [localAuth, setLocalAuth] = useLocalStorage<AuthContextType>('auth', {
    authenticated: false,
  });

  const [auth, setAuth] = useState<AuthContextType>(() => {
    authTokenLoaded.current = true;
    if (localAuth) {
      return localAuth;
    } else {
      return {authenticated: false};
    }
  });
  const handleLogin = React.useCallback(
    async function (username: string, password: string) {
      loggingOut.current = false;

      const result = await loginService.Login(
        domain.LoginRequest.fromPartial({
          username,
          password,
        })
      );

      if (
        result.changePasswordToken != null &&
        result.changePasswordToken !== ''
      ) {
        const url = `/changePassword?token=${encodeURI(
          result.changePasswordToken
        )}`;

        navigate(url);
        return;
      }

      if (
        result.roles.includes(
          domain.UserRole.USER_ROLE_ADMIN ||
            domain.UserRole.USER_ROLE_SUPERVISOR
        )
      ) {
        const newAuth: AuthContextType = {
          authenticated: true,
          ...result,
          username,
          expiry: moment().add(30, 'days').toDate(),
        };

        setAuth(newAuth);
        setLocalAuth(newAuth);
        setUser(result.userId.toString());
        navigate('/home');
      } else if (result.roles.includes(domain.UserRole.USER_ROLE_USER)) {
        alert('Only admins are allowed to login', 'warning');
      }
    },
    [loginService, navigate, setLocalAuth, alert]
  );
  const handleCreatePassword = React.useCallback(
    async function (token: string, password: string) {
      loggingOut.current = false;

      const result = await loginService.CreatePassword(
        domain.CreatePasswordRequest.fromPartial({
          password,
          token,
        })
      );

      if (
        result.roles.includes(
          domain.UserRole.USER_ROLE_ADMIN ||
            domain.UserRole.USER_ROLE_SUPERVISOR
        )
      ) {
        const newAuth: AuthContextType = {
          authenticated: true,
          ...result,
          username: result.username,
          expiry: moment().add(30, 'days').toDate(),
        };

        setAuth(newAuth);
        setLocalAuth(newAuth);
        setUser(result.userId.toString());
      } else if (result.roles.includes(domain.UserRole.USER_ROLE_USER)) {
        alert('Successfully created password', 'success');
      }
    },
    [setLocalAuth, alert, loginService]
  );
  const handleRequestResetPassword = React.useCallback(
    ({username, auth = false}: {username: string; auth?: boolean}) => {
      return loginService.ResetPassword(
        domain.ResetPasswordRequest.fromPartial({username, auth})
      );
    },
    [loginService]
  );

  const handleLogout = useCallback(() => {
    if (auth.authenticated === true) {
      setAuth({authenticated: false});
      setLocalAuth({authenticated: false});
      clearUser();
    }
  }, [auth.authenticated, setLocalAuth]);

  useEffect(() => {
    if (loggingOut.current) {
      handleLogout();
      loggingOut.current = false;
    }
  }, [loggingOut, handleLogout]);

  useEffect(() => {
    let innerAuth: AuthContextType = {authenticated: false};

    // TODO: Localize expiry
    if (localAuth != null) {
      innerAuth = localAuth;

      setAuth(innerAuth);
    }
  }, [localAuth, auth]);

  const expiry = auth.authenticated ? auth.expiry : null;

  useEffect(() => {
    const interval = setInterval(async () => {
      if (auth.authenticated && moment().isSameOrAfter(expiry)) {
        alert('Your session has expired. Please log in again', 'error');
      }
    }, 1000 * 60 * 5);
    return () => clearInterval(interval);
  }, [auth.authenticated, expiry, alert]);

  const hasRole = React.useCallback(
    (role: domain.UserRole) => {
      if (!auth.authenticated) {
        return false;
      }

      if (auth.roles?.indexOf(role) !== -1) {
        return true;
      }

      return false;
    },
    [auth]
  );
  const value = React.useMemo(
    () => ({
      auth,
      handleLogin,
      handleLogout,
      hasRole,
      handleCreatePassword,
      handleRequestResetPassword,
    }),
    [
      auth,
      handleLogin,
      handleLogout,
      hasRole,
      handleCreatePassword,
      handleRequestResetPassword,
    ]
  );

  // Prevent double render before auth token loaded
  if (!authTokenLoaded.current) {
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});

export default AuthContextProvider;
export const useAuthContext = () => useContext(AuthContext);
