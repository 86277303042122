/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdminCreateProjectionsInput = {
    recreateSchema: boolean;
    vesselIds?: Array<string> | null;
    clientMutationId?: string | null;
};
export type AdminProjectionsRecreateMutationVariables = {
    input: AdminCreateProjectionsInput;
};
export type AdminProjectionsRecreateMutationResponse = {
    readonly adminCreateProjections: {
        readonly clientMutationId: string | null;
        readonly projection: {
            readonly " $fragmentRefs": FragmentRefs<"AdminProjectionsRecreate_projection">;
        };
    } | null;
};
export type AdminProjectionsRecreateMutation = {
    readonly response: AdminProjectionsRecreateMutationResponse;
    readonly variables: AdminProjectionsRecreateMutationVariables;
};



/*
mutation AdminProjectionsRecreateMutation(
  $input: AdminCreateProjectionsInput!
) {
  adminCreateProjections(input: $input) {
    clientMutationId
    projection {
      ...AdminProjectionsRecreate_projection
      id
    }
  }
}

fragment AdminProjectionsRecreate_projection on Projection {
  enabled
  counts {
    survey
    loading
    activities
    vesselClients
    vessels
  }
  progress {
    id
    startTime
    endTime
    durationSeconds
    success
    error
    vesselIds
    vesselCount
  }
  queue {
    id
    vesselId
    queueTime
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "AdminCreateProjectionsInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminProjectionsRecreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AdminCreateProjectionsPayload",
        "kind": "LinkedField",
        "name": "adminCreateProjections",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Projection",
            "kind": "LinkedField",
            "name": "projection",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdminProjectionsRecreate_projection"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminProjectionsRecreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AdminCreateProjectionsPayload",
        "kind": "LinkedField",
        "name": "adminCreateProjections",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Projection",
            "kind": "LinkedField",
            "name": "projection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectionCounts",
                "kind": "LinkedField",
                "name": "counts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "survey",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "loading",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activities",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vesselClients",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vessels",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectionProgress",
                "kind": "LinkedField",
                "name": "progress",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "durationSeconds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "error",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vesselIds",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vesselCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectionVesselQueue",
                "kind": "LinkedField",
                "name": "queue",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vesselId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "queueTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AdminProjectionsRecreateMutation",
    "operationKind": "mutation",
    "text": "mutation AdminProjectionsRecreateMutation(\n  $input: AdminCreateProjectionsInput!\n) {\n  adminCreateProjections(input: $input) {\n    clientMutationId\n    projection {\n      ...AdminProjectionsRecreate_projection\n      id\n    }\n  }\n}\n\nfragment AdminProjectionsRecreate_projection on Projection {\n  enabled\n  counts {\n    survey\n    loading\n    activities\n    vesselClients\n    vessels\n  }\n  progress {\n    id\n    startTime\n    endTime\n    durationSeconds\n    success\n    error\n    vesselIds\n    vesselCount\n  }\n  queue {\n    id\n    vesselId\n    queueTime\n  }\n}\n"
  }
};
})();
(node as any).hash = '875c8acb8bb40d9034cf4251c4eb903c';
export default node;
