import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {UpdateOptionDialog} from './components/UpdateOptionDialog';
import {DeleteOptionDialog} from './components/DeleteOptionDialog';
import {AddNewActivityDialog} from './components/AddNewActivityDialog';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import {useAlertContext} from '@deckmans/web-shared';
import {useUpdateDecisionSequenceMutation} from './mutations/UpdateDecisionSequenceMutation';
import useStyles from './LogbookBuilder.styles';
import {Options} from './LogbookBuilderHelper';
import {EditOrOrderSequence} from './components/EditOrOrderSequence';

interface Props {
  decisionOptions: Options;
  decisionId: string;
  openUpdateDialog: boolean;
  openDeleteDialog: boolean;
  openAddDialog: boolean;
  setAddDialog(val: boolean): void;
  setUpdateDialog(val: boolean): void;
  setDeleteDialog(val: boolean): void;
  setSelectedOption(id: string, parentId: string | null): void;
  selectedOption: {id: string; parentId: string | null};
  subheaderText: string;
  selectedIndex: number;
  setSelectedIndex(val: number): void;
  selectedParent: string | null;
  showAddDialog?: boolean;
}

export function DecisionListItem({
  decisionOptions,
  openAddDialog,
  openDeleteDialog,
  openUpdateDialog,
  setDeleteDialog,
  setAddDialog,
  setUpdateDialog,
  setSelectedOption,
  decisionId,
  subheaderText,
  selectedOption,
  selectedIndex,
  setSelectedIndex,
  selectedParent,
  showAddDialog = true,
}: Props) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = React.useState(false);
  const {alert} = useAlertContext();

  const options = _.orderBy(
    decisionOptions && decisionOptions.options,
    ['sequence'],
    ['asc']
  );

  const [updateDecisionSequence] = useUpdateDecisionSequenceMutation();

  const initialSequenceIsNull = options.some(
    (option) => option.sequence === null
  );

  return (
    <List
      className={classes.listBorder}
      subheader={
        <ListSubheader disableSticky={true} className={classes.listHeader}>
          {subheaderText}
          <EditOrOrderSequence
            decisionArray={options}
            decisionId={decisionId}
            isEditing={isEditing}
            initialSequenceIsNull={initialSequenceIsNull}
            setIsEditing={(val) => {
              setIsEditing(val);
            }}
          />
        </ListSubheader>
      }
    >
      {options &&
        options.map((r, index, array) => {
          return (
            <ListItem
              key={r.id}
              button
              selected={selectedIndex === index}
              onClick={() => {
                setSelectedIndex(index);
                setSelectedOption(r.id, r.parentId);
              }}
            >
              <Box width="100%" display="flex" justifyContent="space-between">
                <Typography>{r.name}</Typography>
                {selectedIndex === index && (
                  <Box display="flex" alignItems="center">
                    {!isEditing && (
                      <>
                        <EditRoundedIcon
                          fontSize="small"
                          color="primary"
                          cursor="pointer"
                          onClick={() => {
                            setUpdateDialog(true);
                          }}
                        />
                        <DeleteRoundedIcon
                          fontSize="small"
                          cursor="pointer"
                          color="primary"
                          onClick={() => {
                            setDeleteDialog(true);
                          }}
                        />
                      </>
                    )}

                    {isEditing && (
                      <Box display="flex" pl={2}>
                        {index !== 0 && (
                          <ExpandLessIcon
                            onClick={() => {
                              const temp = r;

                              const item1 = {
                                ...temp,
                                sequence: index,
                              };

                              const item2 = {
                                ...array[index - 1],
                                sequence: index + 1,
                              };

                              const newList = [item1, item2];

                              newList.forEach(async (item) => {
                                try {
                                  const response = await updateDecisionSequence(
                                    {
                                      variables: {
                                        input: {
                                          optionId: item.id,
                                          decisionId,
                                          sequence: item.sequence,
                                        },
                                      },
                                    }
                                  );
                                  if (!response) {
                                    alert('Something went wrong', 'error');
                                  }
                                } catch (error) {
                                  alert('Something went wrong', 'error');
                                }
                                setSelectedIndex(index - 1);
                              });
                            }}
                          />
                        )}
                        {index !== array.length - 1 && (
                          <ExpandMoreIcon
                            onClick={async () => {
                              const temp = r;

                              const item1 = {
                                ...array[index + 1],
                                sequence: index + 1,
                              };
                              const item2 = {
                                ...temp,
                                sequence: index + 2,
                              };

                              const newList = [item1, item2];

                              newList.forEach(async (item) => {
                                try {
                                  const response = await updateDecisionSequence(
                                    {
                                      variables: {
                                        input: {
                                          optionId: item.id,
                                          decisionId,
                                          sequence: item.sequence,
                                        },
                                      },
                                    }
                                  );
                                  if (!response) {
                                    alert('Something went wrong', 'error');
                                  }
                                } catch (error) {
                                  alert('Something went wrong', 'error');
                                }
                                setSelectedIndex(index + 1);
                              });
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </ListItem>
          );
        })}
      <UpdateOptionDialog
        parentId={selectedOption.parentId}
        label={subheaderText}
        decisionId={decisionId}
        open={openUpdateDialog}
        setOpen={() => {
          setUpdateDialog(!openUpdateDialog);
        }}
        optionId={selectedOption.id}
      />
      <DeleteOptionDialog
        open={openDeleteDialog}
        setOpen={() => {
          setDeleteDialog(!openDeleteDialog);
        }}
        optionId={selectedOption.id}
        decisionTypeId={decisionId}
      />

      {showAddDialog && !isEditing && (
        <Box p={1}>
          <AddCircleIcon
            color="secondary"
            onClick={() => {
              setAddDialog(true);
            }}
            cursor="pointer"
          />

          <AddNewActivityDialog
            open={openAddDialog}
            setOpen={() => {
              setAddDialog(false);
            }}
            decisionId={decisionId}
            label={subheaderText}
            parentId={selectedParent}
            sequence={initialSequenceIsNull ? null : options.length + 1}
          />
        </Box>
      )}
    </List>
  );
}
