import React from 'react';
import {Box, MenuItem, Select, Typography} from '@material-ui/core';
import {InventoryTableTrains_train$key} from './__generated__/InventoryTableTrains_train.graphql';
import {InventoryTableStockpiles_stockpile$key} from './__generated__/InventoryTableStockpiles_stockpile.graphql';
import {InventoryTableEqTypes_eqType$key} from './__generated__/InventoryTableEqTypes_eqType.graphql';
import {InventoryTableRoutes_route$key} from './__generated__/InventoryTableRoutes_route.graphql';
import {GraphQLTaggedNode} from 'relay-runtime';
import {InventoryTableTrains} from './InventoryTableTrains';
import {InventoryTableStockpiles} from './InventoryTableStockpiles';
import {InventoryTableEqTypes} from './InventoryTableEqTypes';
import {InventoryTableRoutes} from './InventoryTableRoute';
import {InventoryTableEqNames_eqNames$key} from './__generated__/InventoryTableEqNames_eqNames.graphql';
import {InventoryTableTipplers} from './InventoryTableTipplers';
import {InventoryTableTipplers_tippler$key} from './__generated__/InventoryTableTipplers_tippler.graphql';
import {InventoryTableClients} from './InventoryTableClients';
import {InventoryTableClients_client$key} from './__generated__/InventoryTableClients_client.graphql';
import {InventoryTableOreTypes_oreTypes$key} from './__generated__/InventoryTableOreTypes_oreTypes.graphql';

interface Props {
  query: GraphQLTaggedNode;
  trainKey: InventoryTableTrains_train$key;
  tipplerKey: InventoryTableTipplers_tippler$key;
  stockpileKey: InventoryTableStockpiles_stockpile$key;
  eqTypeKey: InventoryTableEqTypes_eqType$key;
  routeKey: InventoryTableRoutes_route$key;
  eqNameKey: InventoryTableEqNames_eqNames$key;
  clientKey: InventoryTableClients_client$key;
  oreTypeKey: InventoryTableOreTypes_oreTypes$key;
}

const staticList = [
  {id: 'Trains', description: 'Trains'},
  {id: 'Stockpiles', description: 'Stockpiles'},
  {id: 'Routes', description: 'Routes'},
  {id: 'Tipplers', description: 'Tipplers'},
  {id: 'Equipment types', description: 'Equipment types'},
  {id: 'Clients', description: 'Clients'},
];

export function InventoryTable({
  query,
  trainKey,
  stockpileKey,
  eqTypeKey,
  routeKey,
  eqNameKey,
  tipplerKey,
  clientKey,
  oreTypeKey,
}: Props) {
  const [selectedStaticData, setSelectedStaticData] = React.useState(
    staticList[0].id
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
        style={{backgroundColor: 'white'}}
      >
        <Box pl={1.5} width="100%">
          <Typography variant="h6">Inventory list</Typography>
        </Box>
        <Box display="flex" width="100%">
          <Box width="100%" pr={1}>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                const value = e.target.value as string;
                setSelectedStaticData(value);
              }}
              value={selectedStaticData}
            >
              {staticList.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.description}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        </Box>
      </Box>
      {selectedStaticData === 'Trains' && (
        <InventoryTableTrains query={query} trainKey={trainKey} />
      )}
      {selectedStaticData === 'Stockpiles' && (
        <InventoryTableStockpiles query={query} stockpileKey={stockpileKey} />
      )}
      {selectedStaticData === 'Routes' && (
        <InventoryTableRoutes query={query} routeKey={routeKey} />
      )}
      {selectedStaticData === 'Tipplers' && (
        <InventoryTableTipplers query={query} tipplerKey={tipplerKey} />
      )}

      {selectedStaticData === 'Equipment types' && (
        <InventoryTableEqTypes
          query={query}
          eqTypeKey={eqTypeKey}
          eqNameKey={eqNameKey}
        />
      )}
      {selectedStaticData === 'Clients' && (
        <InventoryTableClients
          query={query}
          clientKey={clientKey}
          oreTypeKey={oreTypeKey}
        />
      )}
    </>
  );
}
