/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdminProjectionsRecreate_projection = {
    readonly enabled: boolean;
    readonly counts: {
        readonly survey: number;
        readonly loading: number;
        readonly activities: number;
        readonly vesselClients: number;
        readonly vessels: number;
    };
    readonly progress: ReadonlyArray<{
        readonly id: string;
        readonly startTime: string;
        readonly endTime: string | null;
        readonly durationSeconds: number | null;
        readonly success: boolean | null;
        readonly error: string | null;
        readonly vesselIds: string | null;
        readonly vesselCount: number | null;
    }>;
    readonly queue: ReadonlyArray<{
        readonly id: string;
        readonly vesselId: string;
        readonly queueTime: string;
    }>;
    readonly " $refType": "AdminProjectionsRecreate_projection";
};
export type AdminProjectionsRecreate_projection$data = AdminProjectionsRecreate_projection;
export type AdminProjectionsRecreate_projection$key = {
    readonly " $data"?: AdminProjectionsRecreate_projection$data;
    readonly " $fragmentRefs": FragmentRefs<"AdminProjectionsRecreate_projection">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminProjectionsRecreate_projection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectionCounts",
      "kind": "LinkedField",
      "name": "counts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "survey",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loading",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activities",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vesselClients",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vessels",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectionProgress",
      "kind": "LinkedField",
      "name": "progress",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "durationSeconds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "success",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "error",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vesselIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vesselCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectionVesselQueue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vesselId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "queueTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Projection"
};
})();
(node as any).hash = '82ab5858e2d5f0e8fecfa4a67ea06917';
export default node;
