import * as React from 'react';
import {Redirect} from 'react-router';
import {Formik, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import Paper from '@material-ui/core/Paper';
import {useAuthContext} from 'contexts/AuthContext';
import BG from 'images/BG.jpg';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {AngloNamedLogo} from 'images/anglo-named-logo';
import {Link} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundImage: `url(${BG})`,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
  },
  textBG: {
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const schema = yup
  .object({
    username: yup.string().required('This field is required'),
    password: yup.string().required('This field is required'),
  })
  .required();

type Values = yup.InferType<typeof schema>;

export function LoginPage() {
  const classes = useStyles();

  const {handleLogin, auth} = useAuthContext();

  const [errorMessage, setErrorMessage] = React.useState('');
  if (auth.authenticated) {
    return <Redirect to="/home" />;
  }
  return (
    <Box height="100vh" display="flex" alignItems="center">
      <Formik<Values>
        validationSchema={schema}
        initialValues={{username: '', password: ''}}
        onSubmit={async ({username, password}, {setFieldValue}) => {
          setErrorMessage('');
          try {
            await handleLogin(username, password);
          } catch (ex) {
            setErrorMessage('Your username or password is incorrect!');
          } finally {
            setFieldValue('password', '');
          }
        }}
      >
        {({submitForm, isSubmitting}) => (
          <Box className={classes.background}>
            <Box display="flex" width="50%" justifyContent="center">
              <Paper className={classes.paper}>
                <Box display="flex" flexDirection="column" pt={1.5}>
                  <Typography
                    color="primary"
                    variant="h1"
                    className={classes.textBG}
                  >
                    Deckmans
                  </Typography>
                  <Typography
                    color="primary"
                    variant="h3"
                    className={classes.textBG}
                  >
                    Admin Portal
                  </Typography>
                </Box>

                <Box>
                  <Box m={2} mb={3} display="flex" justifyContent="center">
                    <AngloNamedLogo />
                  </Box>

                  <Box m={2} mb={3}>
                    <Field
                      component={TextField}
                      name="username"
                      fullWidth
                      label="Username"
                    />
                  </Box>
                  <Box m={2} mb={3}>
                    <Field
                      component={TextField}
                      name="password"
                      type="password"
                      fullWidth
                      label="Password"
                    />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    {errorMessage !== '' && (
                      <Typography variant="caption" color="error">
                        {errorMessage}
                      </Typography>
                    )}
                  </Box>
                  <Box m={2} mb={3}>
                    <Button
                      onClick={submitForm}
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      disabled={isSubmitting}
                    >
                      Login
                    </Button>
                  </Box>
                  <Box
                    m={2}
                    mb={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Link href="/resetPassword">Reset Password</Link>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
}
