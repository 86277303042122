/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type TipplerUpdateInput = {
    id: string;
    name: string;
    clientMutationId?: string | null;
};
export type InventoryTableTipplersUpdateMutationVariables = {
    input: TipplerUpdateInput;
};
export type InventoryTableTipplersUpdateMutationResponse = {
    readonly tipplerUpdate: {
        readonly tippler: {
            readonly id: string;
            readonly name: string;
        };
    } | null;
};
export type InventoryTableTipplersUpdateMutation = {
    readonly response: InventoryTableTipplersUpdateMutationResponse;
    readonly variables: InventoryTableTipplersUpdateMutationVariables;
};



/*
mutation InventoryTableTipplersUpdateMutation(
  $input: TipplerUpdateInput!
) {
  tipplerUpdate(input: $input) {
    tippler {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "TipplerUpdateInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TipplerUpdatePayload",
    "kind": "LinkedField",
    "name": "tipplerUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Tippler",
        "kind": "LinkedField",
        "name": "tippler",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InventoryTableTipplersUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InventoryTableTipplersUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InventoryTableTipplersUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation InventoryTableTipplersUpdateMutation(\n  $input: TipplerUpdateInput!\n) {\n  tipplerUpdate(input: $input) {\n    tippler {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9d6edad244fa06b33f6a1e64a25cabf5';
export default node;
