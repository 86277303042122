import {makeStyles, Theme} from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  listBorder: {
    borderRight: '1px solid',
    borderRightColor: theme.palette.divider,
    width: '100%',
  },
  listBorderSingle: {
    width: '100%',
  },
  listWidth: {
    width: '100%',
  },
  listHeader: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
