import * as React from 'react';
import {useHistory} from 'react-router';
import {Formik, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import Paper from '@material-ui/core/Paper';
import {useAuthContext} from 'contexts/AuthContext';
import BG from 'images/BG.jpg';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {AngloNamedLogo} from 'images/anglo-named-logo';
import {useSearchParam} from 'react-use';
import {useAlertContext} from '@deckmans/web-shared';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundImage: `url(${BG})`,
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
  },
  textBG: {
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const validationSchema = yup
  .object({
    password: yup
      .string()
      .min(15, 'The password needs to be 15 characters.')
      .required('Enter a password'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match.')
      .required('This is a required field'),
  })
  .required();

type Values = yup.InferType<typeof validationSchema>;
interface Props {
  label?: string;
  message?: string;
}
export function CreatePassword({label = 'Create Password', message}: Props) {
  const token = useSearchParam('token');
  const classes = useStyles();
  const history = useHistory();

  const {handleCreatePassword} = useAuthContext();
  const {alert} = useAlertContext();
  const [errorMessage, setErrorMessage] = React.useState('');
  if (!token) {
    return <>No token provided</>;
  }

  return (
    <Box height="100vh" display="flex" alignItems="center">
      <Formik<Values>
        validationSchema={validationSchema}
        initialValues={{password: '', confirmPassword: ''}}
        onSubmit={async ({password}) => {
          setErrorMessage('');
          try {
            await handleCreatePassword(token, password);
            //password has been created and user will be logged in
            history.push('/home');
          } catch (ex) {
            setErrorMessage('Something went wrong!');
            alert(ex.message, 'error');
          }
        }}
      >
        {({submitForm, isSubmitting}) => (
          <Box className={classes.background}>
            <Box display="flex" width="50%" justifyContent="center">
              <Paper className={classes.paper}>
                <Box display="flex" flexDirection="column" pt={1.5}>
                  <Typography
                    color="primary"
                    variant="h1"
                    className={classes.textBG}
                  >
                    Deckmans
                  </Typography>
                  <Typography
                    color="primary"
                    variant="h3"
                    className={classes.textBG}
                  >
                    {label}
                  </Typography>
                  {message && (
                    <Typography
                      color="primary"
                      variant="body2"
                      style={{maxWidth: 360}}
                      align="center"
                    >
                      {message}
                    </Typography>
                  )}
                </Box>

                <Box>
                  <Box m={2} mb={3} display="flex" justifyContent="center">
                    <AngloNamedLogo />
                  </Box>

                  <Box m={2} mb={3}>
                    <Field
                      component={TextField}
                      name="password"
                      type="password"
                      fullWidth
                      label="Password"
                    />
                  </Box>
                  <Box m={2} mb={3}>
                    <Field
                      component={TextField}
                      name="confirmPassword"
                      type="password"
                      fullWidth
                      label="Confirm Password"
                    />
                  </Box>
                  <Box display="flex" justifyContent="center">
                    {errorMessage !== '' && (
                      <Typography variant="caption" color="error">
                        {errorMessage}
                      </Typography>
                    )}
                  </Box>
                  <Box m={2} mb={3}>
                    <Button
                      onClick={submitForm}
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      disabled={isSubmitting}
                    >
                      Create Password
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
}
