import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {AuthContextProvider} from 'contexts/AuthContext';
import {Routes} from './Routes';
import {ThemeController} from '@deckmans/web-shared';
import {HeaderRouter} from 'modules/Header/Header';
import {
  TitleContextController,
  AlertContextProvider,
  FullPageError,
} from '@deckmans/web-shared';

import {useCreateRelayEnv} from './CreateRelayEnv';
import {RelayEnvironmentProvider} from 'relay-hooks';

interface EBProps {
  children: JSX.Element;
}

function ErrorBoundary({children}: EBProps) {
  let child: JSX.Element | null = null;
  try {
    child = children;
  } catch (error) {
    child = <FullPageError error={error} />;
  }
  return child;
}

export const App = React.memo(function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AlertContextProvider>
          <AuthContextProvider>
            <CoreApp />
          </AuthContextProvider>
        </AlertContextProvider>
      </Router>
    </ErrorBoundary>
  );
});
function CoreApp() {
  const environment = useCreateRelayEnv();

  return (
    <RelayEnvironmentProvider environment={environment}>
      <ThemeController>
        <TitleContextController>
          <HeaderRouter />
          <Routes />
        </TitleContextController>
      </ThemeController>
    </RelayEnvironmentProvider>
  );
}
