import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {AddNewActivityDialog} from './components/AddNewActivityDialog';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {UpdateOptionDialog} from './components/UpdateOptionDialog';
import {DeleteOptionDialog} from './components/DeleteOptionDialog';
import _ from 'lodash';
import {EditOrOrderSequence} from './components/EditOrOrderSequence';
import SequenceOrderButtons from './components/SequenceOrderButtons';
import useStyles from './LogbookBuilder.styles';
import {DecTypes, Options} from './LogbookBuilderHelper';

interface Props {
  awaitingShiploaderDecisions: DecTypes[];
}

export function LogbookBuilderAwaitingShiploader({
  awaitingShiploaderDecisions,
}: Props) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = React.useState(false);

  const awaitingSlDecisionId = awaitingShiploaderDecisions.map((dec) => {
    return dec.id;
  })[0];

  const awaitingSlOptions = React.useMemo(() => {
    let awaitingSlOptions: Options[] = [
      {
        option: '',
        options: [
          {
            id: '',
            name: '',
            freeText: false,
            parentId: '',
            label: '',
            key: '',
            sequence: null,
          },
        ],
      },
    ];

    awaitingShiploaderDecisions.forEach((a) => {
      const opt = a.options;
      const awaitingSlOpts = _.chain(opt)
        .groupBy('parentId')
        .map((value, key) => ({option: key, options: value}))
        .value();

      awaitingSlOptions = awaitingSlOpts;
    });
    return awaitingSlOptions;
  }, [awaitingShiploaderDecisions]);

  let awaitingSlParentOptions: Options = {
    option: '',
    options: [
      {
        id: '',
        name: '',
        freeText: false,
        parentId: '',
        label: '',
        key: '',
        sequence: null,
      },
    ],
  };

  if (awaitingSlOptions) {
    awaitingSlParentOptions = awaitingSlOptions.filter((a) => {
      return a.option === 'null';
    })[0];
  }

  const options = _.orderBy(
    awaitingSlParentOptions && awaitingSlParentOptions.options,
    ['sequence'],
    ['asc']
  );
  const [selected, setSelected] = React.useState(0);

  const handleListItemClick = (index: number) => {
    setSelected(index);
  };

  const [selectedActivity, setSelectedActivity] = React.useState('');

  const [openAddDialog, setOpenAddDialog] = React.useState(false);

  const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);

  const [openDeleteDialog, setDeleteDialog] = React.useState(false);

  const initialSequenceIsNull = options.some(
    (option) => option.sequence === null
  );

  return (
    <>
      <Box display="flex" width="100%" justifyContent="space-between">
        <List
          className={classes.listBorderSingle}
          subheader={
            <ListSubheader disableSticky={true} className={classes.listHeader}>
              Reason
              <EditOrOrderSequence
                decisionArray={options}
                decisionId={awaitingSlDecisionId}
                isEditing={isEditing}
                initialSequenceIsNull={initialSequenceIsNull}
                setIsEditing={(val) => {
                  setIsEditing(val);
                }}
              />
            </ListSubheader>
          }
        >
          {options &&
            options.map((r, index, array) => {
              return (
                <ListItem
                  key={r.id}
                  button
                  selected={selected === index}
                  onClick={() => {
                    handleListItemClick(index);
                    setSelectedActivity(r.id);
                  }}
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Typography>{r.name}</Typography>
                    {selected === index && (
                      <Box display="flex" alignItems="center">
                        {!isEditing && (
                          <>
                            <EditRoundedIcon
                              fontSize="small"
                              color="primary"
                              cursor="pointer"
                              onClick={() => {
                                setOpenUpdateDialog(true);
                              }}
                            />
                            <DeleteRoundedIcon
                              fontSize="small"
                              cursor="pointer"
                              color="primary"
                              onClick={() => {
                                setDeleteDialog(true);
                              }}
                            />
                          </>
                        )}
                        {isEditing && (
                          <SequenceOrderButtons
                            index={index}
                            array={array}
                            decisionId={awaitingSlDecisionId}
                            setSelected={(val) => {
                              setSelected(val);
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </ListItem>
              );
            })}
          <UpdateOptionDialog
            parentId={null}
            label="Reason"
            decisionId={awaitingSlDecisionId}
            open={openUpdateDialog}
            setOpen={() => {
              setOpenUpdateDialog(!openUpdateDialog);
            }}
            optionId={selectedActivity}
          />
          <DeleteOptionDialog
            open={openDeleteDialog}
            setOpen={() => {
              setDeleteDialog(!openDeleteDialog);
            }}
            optionId={selectedActivity}
            decisionTypeId={awaitingSlDecisionId}
          />
          {!isEditing && (
            <Box p={1}>
              <AddCircleIcon
                color="secondary"
                onClick={() => {
                  setOpenAddDialog(true);
                }}
                cursor="pointer"
              />
              <AddNewActivityDialog
                open={openAddDialog}
                setOpen={() => {
                  setOpenAddDialog(false);
                }}
                decisionId={awaitingSlDecisionId}
                label="Reason"
                parentId={null}
                sequence={initialSequenceIsNull ? null : options.length + 1}
              />
            </Box>
          )}
        </List>
      </Box>
    </>
  );
}
