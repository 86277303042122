import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {ConfigService, getMetaTagContent} from '@stackworx/react';
import {createBrowserHistory} from 'history';
import * as Common from '@microsoft/applicationinsights-common';
import {ICustomProperties} from '@microsoft/applicationinsights-core-js';

// Do not send notifications for local development
const stage = ConfigService.stage;

const browserHistory = createBrowserHistory({basename: ''});

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getMetaTagContent('app:appinsights') ?? undefined,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {history: browserHistory},
    },
  },
});
export type notifyCallFn = (exception: Error, severity?: SeverityLevel) => void;

if (stage !== 'local') {
  appInsights.loadAppInsights();
}

export function trackAiEvent(
  event: Common.IEventTelemetry,
  customProperties?: ICustomProperties
) {
  if (appInsights && stage !== 'local') {
    appInsights.trackEvent(event, customProperties);
  }
}

export function notify(exception: Error, severity = SeverityLevel.Error) {
  if (appInsights && stage !== 'local') {
    appInsights.trackException({
      error: exception,
      severityLevel: severity,
    });
  }
}

export function setUser(id: string) {
  if (appInsights && stage !== 'local') {
    appInsights.setAuthenticatedUserContext(id);
  }
}

export function clearUser() {
  if (appInsights && stage !== 'local') {
    appInsights.clearAuthenticatedUserContext();
  }
}
