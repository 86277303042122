import React from 'react';
import {Box} from '@material-ui/core';
import _ from 'lodash';
import {DecisionListItem} from './DecisionListItem';

interface Props {
  breakdownDecisions: DecTypes[];
}

interface DecTypes {
  readonly id: string;
  readonly key: string;
  readonly options: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly freeText: boolean;
    readonly parentId: string | null;
    readonly label: string;
    readonly key: string;
    readonly sequence: number | null;
  }>;
}

export function LogbookBuilderBreakdown({breakdownDecisions}: Props) {
  const breakdownDecisionId = breakdownDecisions.map((dec) => {
    return dec.id;
  })[0];

  const breakdownOptions = React.useMemo(() => {
    let breakdownOptions: {
      option: string;
      label: string;
      options: {
        id: string;
        name: string;
        freeText: boolean;
        parentId: string | null;
        label: string;
        key: string;
        sequence: number | null;
      }[];
    }[] = [
      {
        option: '',
        label: '',
        options: [
          {
            id: '',
            name: '',
            freeText: false,
            parentId: '',
            label: '',
            key: '',
            sequence: null,
          },
        ],
      },
    ];

    breakdownDecisions.forEach((a) => {
      const opt = a.options;

      const stopVesselOpts = _.chain(opt)
        .groupBy('parentId')
        .map((value, key) => ({
          option: key,
          label: value[0].label,
          options: value,
        }))
        .value();

      breakdownOptions = stopVesselOpts;
    });
    return breakdownOptions;
  }, [breakdownDecisions]);

  let breakdownParentOptions: {
    option: string;
    options: {
      id: string;
      name: string;
      freeText: boolean;
      parentId: string | null;
      label: string;
      key: string;
      sequence: number | null;
    }[];
  } = {
    option: '',
    options: [
      {
        id: '',
        name: '',
        freeText: false,
        parentId: '',
        label: '',
        key: '',
        sequence: null,
      },
    ],
  };

  if (breakdownOptions) {
    breakdownParentOptions = breakdownOptions.filter((a) => {
      return a.option === 'null';
    })[0];
  }

  const [selected, setSelected] = React.useState(-1);

  const [selectedActivity, setSelectedActivity] = React.useState<{
    id: string;
    parentId: string | null;
  }>({
    id: '',
    parentId: '',
  });

  const [selectedReason2, setSelectedReason2] = React.useState<{
    id: string;
    parentId: string | null;
  }>({id: '', parentId: ''});

  const [
    selectedEquipmentTypeIndex,
    setSelectedEquipmentTypeIndex,
  ] = React.useState(-1);
  const [selectedReason1Index, setSelectedReason1Index] = React.useState(-1);
  const [selectedReason2Index, setSelectedReason2Index] = React.useState(-1);

  const [openAddDialog, setOpenAddDialog] = React.useState({
    openBreakdownAddDialog: false,
    openEqTypeAddDialog: false,
    openReason1AddDialog: false,
    openReason2AddDialog: false,
  });

  const [openUpdateDialog, setOpenUpdateDialog] = React.useState({
    openBreakdownUpdateDialog: false,
    openEqTypeUpdateDialog: false,
    openReason1UpdateDialog: false,
    openReason2UpdateDialog: false,
  });

  const [openDeleteDialog, setDeleteDialog] = React.useState({
    openBreakdownDelDialog: false,
    openEqTypeDelDialog: false,
    openReason1DelDialog: false,
    openReason2DelDialog: false,
  });

  const eqTypeOptions = breakdownOptions.filter((a) => {
    return a.option === selectedActivity.id && a.label === 'Equipment Type';
  })[0];

  const [selectedEqType, setSelectedEqType] = React.useState<{
    id: string;
    parentId: string | null;
  }>({
    id: '',
    parentId: '',
  });

  const reason1Options = breakdownOptions.filter((a) => {
    const id = !eqTypeOptions ? selectedActivity.id : selectedEqType.id;
    return a.option === id;
  })[0];
  const [selectedReason1, setSelectedReason1] = React.useState<{
    id: string;
    parentId: string | null;
  }>({
    id: '',
    parentId: '',
  });
  const reason2Options = breakdownOptions.filter((a) => {
    return a.option === selectedReason1.id;
  })[0];

  return (
    <>
      <Box display="flex" width="100%" justifyContent="space-between">
        <DecisionListItem
          selectedIndex={selected}
          setSelectedIndex={(val: number) => {
            setSelected(val);
            setSelectedEqType({id: '', parentId: ''});
            setSelectedEquipmentTypeIndex(-1);
            setSelectedReason1({id: '', parentId: ''});
            setSelectedReason1Index(-1);
            setSelectedReason2({id: '', parentId: ''});
            setSelectedReason2Index(-1);
          }}
          decisionId={breakdownDecisionId}
          subheaderText="Breakdown"
          decisionOptions={breakdownParentOptions}
          openAddDialog={openAddDialog.openBreakdownAddDialog}
          openDeleteDialog={openDeleteDialog.openBreakdownDelDialog}
          openUpdateDialog={openUpdateDialog.openBreakdownUpdateDialog}
          setAddDialog={() => {
            setOpenAddDialog({
              openBreakdownAddDialog: !openAddDialog.openBreakdownAddDialog,
              openEqTypeAddDialog: false,
              openReason1AddDialog: false,
              openReason2AddDialog: false,
            });
          }}
          setDeleteDialog={() => {
            setDeleteDialog({
              openBreakdownDelDialog: !openDeleteDialog.openBreakdownDelDialog,
              openEqTypeDelDialog: false,
              openReason1DelDialog: false,
              openReason2DelDialog: false,
            });
          }}
          setUpdateDialog={() => {
            setOpenUpdateDialog({
              openBreakdownUpdateDialog: !openUpdateDialog.openBreakdownUpdateDialog,
              openEqTypeUpdateDialog: false,
              openReason1UpdateDialog: false,
              openReason2UpdateDialog: false,
            });
          }}
          selectedOption={selectedActivity}
          setSelectedOption={(id, parentId) => {
            setSelectedActivity({id: id, parentId: parentId});
          }}
          selectedParent={selectedActivity.parentId}
        />

        <DecisionListItem
          selectedIndex={selectedEquipmentTypeIndex}
          setSelectedIndex={(val: number) => {
            setSelectedEquipmentTypeIndex(val);
            setSelectedReason1({id: '', parentId: ''});
            setSelectedReason1Index(-1);
            setSelectedReason2({id: '', parentId: ''});
            setSelectedReason2Index(-1);
          }}
          decisionId={breakdownDecisionId}
          subheaderText="Equipment Type"
          decisionOptions={eqTypeOptions}
          openAddDialog={openAddDialog.openEqTypeAddDialog}
          openDeleteDialog={openDeleteDialog.openEqTypeDelDialog}
          openUpdateDialog={openUpdateDialog.openEqTypeUpdateDialog}
          setAddDialog={() => {
            setOpenAddDialog({
              openBreakdownAddDialog: false,
              openEqTypeAddDialog: !openAddDialog.openEqTypeAddDialog,
              openReason1AddDialog: false,
              openReason2AddDialog: false,
            });
          }}
          setDeleteDialog={() => {
            setDeleteDialog({
              openBreakdownDelDialog: false,
              openEqTypeDelDialog: !openDeleteDialog.openEqTypeDelDialog,
              openReason1DelDialog: false,
              openReason2DelDialog: false,
            });
          }}
          setUpdateDialog={() => {
            setOpenUpdateDialog({
              openBreakdownUpdateDialog: false,
              openEqTypeUpdateDialog: !openUpdateDialog.openEqTypeUpdateDialog,
              openReason1UpdateDialog: false,
              openReason2UpdateDialog: false,
            });
          }}
          selectedOption={selectedEqType}
          setSelectedOption={(id, parentId) => {
            setSelectedEqType({id: id, parentId: parentId});
          }}
          selectedParent={selectedActivity.id}
          showAddDialog={
            reason1Options && !eqTypeOptions
              ? false
              : selected !== -1
              ? true
              : false
          }
        />

        <DecisionListItem
          selectedIndex={selectedReason1Index}
          setSelectedIndex={(val: number) => {
            setSelectedReason1Index(val);
            setSelectedReason2({id: '', parentId: ''});
            setSelectedReason2Index(-1);
          }}
          decisionId={breakdownDecisionId}
          subheaderText="Reason 1"
          decisionOptions={reason1Options}
          openAddDialog={openAddDialog.openReason1AddDialog}
          openDeleteDialog={openDeleteDialog.openReason1DelDialog}
          openUpdateDialog={openUpdateDialog.openReason1UpdateDialog}
          setAddDialog={() => {
            setOpenAddDialog({
              openBreakdownAddDialog: false,
              openEqTypeAddDialog: false,
              openReason1AddDialog: !openAddDialog.openReason1AddDialog,
              openReason2AddDialog: false,
            });
          }}
          setDeleteDialog={() => {
            setDeleteDialog({
              openBreakdownDelDialog: false,
              openEqTypeDelDialog: false,
              openReason1DelDialog: !openDeleteDialog.openReason1DelDialog,
              openReason2DelDialog: false,
            });
          }}
          setUpdateDialog={() => {
            setOpenUpdateDialog({
              openBreakdownUpdateDialog: false,
              openEqTypeUpdateDialog: false,
              openReason1UpdateDialog: !openUpdateDialog.openReason1UpdateDialog,
              openReason2UpdateDialog: false,
            });
          }}
          selectedOption={selectedReason1}
          setSelectedOption={(id, parentId) => {
            setSelectedReason1({id: id, parentId: parentId});
          }}
          selectedParent={
            selectedEqType.id !== '' ? selectedEqType.id : selectedActivity.id
          }
          showAddDialog={
            reason1Options && !eqTypeOptions
              ? selected !== -1
                ? true
                : false
              : selectedEquipmentTypeIndex !== -1
              ? true
              : false
          }
        />

        <DecisionListItem
          selectedIndex={selectedReason2Index}
          setSelectedIndex={(val: number) => {
            setSelectedReason2Index(val);
          }}
          decisionId={breakdownDecisionId}
          subheaderText="Reason 2"
          decisionOptions={reason2Options}
          openAddDialog={openAddDialog.openReason2AddDialog}
          openDeleteDialog={openDeleteDialog.openReason2DelDialog}
          openUpdateDialog={openUpdateDialog.openReason2UpdateDialog}
          setAddDialog={() => {
            setOpenAddDialog({
              openBreakdownAddDialog: false,
              openEqTypeAddDialog: false,
              openReason1AddDialog: false,
              openReason2AddDialog: !openAddDialog.openReason2AddDialog,
            });
          }}
          setDeleteDialog={() => {
            setDeleteDialog({
              openBreakdownDelDialog: false,
              openEqTypeDelDialog: false,
              openReason1DelDialog: false,
              openReason2DelDialog: !openDeleteDialog.openReason2DelDialog,
            });
          }}
          setUpdateDialog={() => {
            setOpenUpdateDialog({
              openBreakdownUpdateDialog: false,
              openEqTypeUpdateDialog: false,
              openReason1UpdateDialog: false,
              openReason2UpdateDialog: !openUpdateDialog.openReason2UpdateDialog,
            });
          }}
          selectedOption={selectedReason2}
          setSelectedOption={(id, parentId) => {
            setSelectedReason2({id: id, parentId: parentId});
          }}
          selectedParent={selectedReason1.id}
          showAddDialog={selectedReason1.id === '' ? false : true}
        />
      </Box>
    </>
  );
}
