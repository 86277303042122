import {
  LoginServiceClientImpl,
  EventServiceClientImpl,
  MasterServiceClientImpl,
} from '@deckmans/domain';
import {requestFactory} from '@deckmans/web-shared';
import {notify} from 'appInsights';

export function createLoginService() {
  return new LoginServiceClientImpl({
    request: requestFactory(async () => '', notify),
  });
}
export function createMasterDataService() {
  return new MasterServiceClientImpl({
    request: requestFactory(async () => '', notify),
  });
}

export function createEventService(getToken: () => Promise<string>) {
  return new EventServiceClientImpl({
    request: requestFactory(getToken, notify),
  });
}
export function createMasterService(getToken: () => Promise<string>) {
  return new EventServiceClientImpl({
    request: requestFactory(getToken, notify),
  });
}
