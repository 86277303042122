import React from 'react';
import {useTitle} from '@deckmans/web-shared';
import graphql from 'babel-plugin-relay/macro';
import {RelayRenderer} from 'modules/RelayRenderer/RelayRenderer';
import {UserRolesTable} from './UserRolesTable';

const query = graphql`
  query UserRolesQuery($cursor: String, $count: Int = 10) {
    viewer {
      ...UserRolesTable_users
      ...UserRolesTable_client
    }
  }
`;

export function UserRoles() {
  useTitle('User Management');

  return (
    <RelayRenderer query={query}>
      {(props) => {
        return (
          <div>
            {props && props?.viewer && (
              <UserRolesTable
                query={query}
                paginationKey={props.viewer}
                clientPaginationKey={props.viewer}
              />
            )}
          </div>
        );
      }}
    </RelayRenderer>
  );
}
