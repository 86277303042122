import React from 'react';
import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import {LogbookBuilderTable_decisions$key} from './__generated__/LogbookBuilderTable_decisions.graphql';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {LogbookBuilderStopVessel} from './LogbookBuilderStopVessel';
import {ExpansionRow} from './ExpansionRow';
import {LogbookBuilderBreakdown} from './LogbookBuilderBreakdown';
import {LogbookBuilderSingleLoading} from './LogbookBuilderSingleLoading';
import {LogbookBuilderReclaiming} from './LogbookBuilderReclaiming';
import {LogbookBuilderAwaitingShiploader} from './LogbookBuilderAwaitingShiploader';
import {LogbookBuilderVesselDelay} from './LogbookBuilderAwaitingVesselDelay';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    boxShadow: 'none',
    width: '100%',
  },
  expansionSummaryBorder: {
    borderColor: theme.palette.divider,
  },
  expansionContainer: {
    boxShadow: theme.shadows[8],
    borderRadius: '4px',
  },
}));

const IconLeftExpansionPanelSummary = withStyles({
  expandIcon: {
    order: -1,
  },
})(ExpansionPanelSummary);

interface Props {
  fragmentKey: LogbookBuilderTable_decisions$key;
}

const fragment = graphql`
  fragment LogbookBuilderTable_decisions on Master {
    decisions {
      id
      key
      options {
        id
        name
        freeText
        parentId
        label
        key
        sequence
      }
    }
  }
`;

export function LogbookBuilderTable({fragmentKey}: Props) {
  const classes = useStyles();
  const decisions = useFragment(fragment, fragmentKey);

  const stopVesselDecisions = decisions.decisions.filter((dec) => {
    return dec.key === 'DECISION_TYPE_STOP_VESSEL_ACTIVITY';
  });

  const breakdownActivityDecisions = decisions.decisions.filter((dec) => {
    return dec.key === 'DECISION_TYPE_BREAKDOWN_ACTIVITY';
  });
  const reclaimingActivityDecisions = decisions.decisions.filter((dec) => {
    return dec.key === 'DECISION_TYPE_RECLAIM_ACTIVITY';
  });
  const singleLoadingActivityDecisions = decisions.decisions.filter((dec) => {
    return dec.key === 'DECISION_TYPE_SINGLE_LOADING_ACTIVITY';
  });
  const vesselDelayOptions = decisions.decisions.filter((dec) => {
    return dec.key === 'DECISION_TYPE_VESSEL_DELAY_OPTIONS';
  });
  const awaitingShiploaderDecisions = decisions.decisions.filter((dec) => {
    return dec.key === 'DECISION_TYPE_NON_HATCH_AWAITING_SHIPLOADER_ACTIVITY';
  });

  return (
    <Box pb={4}>
      <ExpansionPanel className={classes.expansionContainer}>
        <Box borderBottom={1} className={classes.expansionSummaryBorder}>
          <IconLeftExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            IconButtonProps={{edge: 'start'}}
          >
            <Typography>Vessel</Typography>
          </IconLeftExpansionPanelSummary>
        </Box>
        <ExpansionPanelDetails>
          <ExpansionRow name="Vessel activities">
            <ExpansionRow name="Stop Vessel">
              <LogbookBuilderStopVessel
                stopVesselDecisions={stopVesselDecisions}
              />
            </ExpansionRow>
          </ExpansionRow>
        </ExpansionPanelDetails>
        <ExpansionPanelDetails>
          <ExpansionRow name="Vessel Delay">
            <LogbookBuilderVesselDelay
              vesselDelayReasons={vesselDelayOptions}
            />
          </ExpansionRow>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Box mt={2}>
        <ExpansionPanel className={classes.expansionContainer}>
          <Box borderBottom={1} className={classes.expansionSummaryBorder}>
            <IconLeftExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              IconButtonProps={{edge: 'start'}}
            >
              <Typography>Shiploader Activity</Typography>
            </IconLeftExpansionPanelSummary>
          </Box>
          <ExpansionPanelDetails>
            <ExpansionRow name="Breakdown Activities">
              <LogbookBuilderBreakdown
                breakdownDecisions={breakdownActivityDecisions}
              />
            </ExpansionRow>
          </ExpansionPanelDetails>
          <ExpansionPanelDetails>
            <ExpansionRow name="Single Loading Activities">
              <LogbookBuilderSingleLoading
                singleLoadingDecisions={singleLoadingActivityDecisions}
              />
            </ExpansionRow>
          </ExpansionPanelDetails>
          <ExpansionPanelDetails>
            <ExpansionRow name="Reclaiming Loading Activities">
              <LogbookBuilderReclaiming
                reclaimingDecisions={reclaimingActivityDecisions}
              />
            </ExpansionRow>
          </ExpansionPanelDetails>
          <ExpansionPanelDetails>
            <ExpansionRow name="Awaiting Shiploader Activities">
              <LogbookBuilderAwaitingShiploader
                awaitingShiploaderDecisions={awaitingShiploaderDecisions}
              />
            </ExpansionRow>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
    </Box>
  );
}
