import React from 'react';
import {QueryOptions, useQuery} from 'relay-hooks';
import Button from '@material-ui/core/Button';
import {GraphQLTaggedNode} from 'react-relay';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

export function RelayRenderer<
  TOperationType extends {
    //eslint-disable-next-line
    variables?: any;
    //eslint-disable-next-line
    response?: any;
    //eslint-disable-next-line
  } = any
>({
  query,
  variables,
  options,
  children,
}: {
  query: GraphQLTaggedNode;
  variables?: TOperationType['variables'];
  options?: QueryOptions;
  children: (
    props: TOperationType['response'] | null | undefined,
    cached: boolean | undefined
  ) => JSX.Element;
}): JSX.Element {
  const {error, props, retry, cached} = useQuery(query, variables, options);
  if (error) {
    return (
      <>
        We have encountered an error
        <br />
        <Button onClick={() => retry()}>Retry</Button>
      </>
    );
  }
  if (!error && !props) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  return children(props, cached);
}
