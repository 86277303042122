import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {ConnectionConfig, usePagination} from 'relay-hooks';
import {useRelayPagination} from 'hooks/useRelayPagination';
import {InventoryTableTrains_train$key} from './__generated__/InventoryTableTrains_train.graphql';
import MaterialTable from 'material-table';
import {makeStyles, Paper, TablePagination, TextField} from '@material-ui/core';
import {useMutation} from 'relay-hooks';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {InventoryTableTrainsDeleteMutation} from './__generated__/InventoryTableTrainsDeleteMutation.graphql';
import {InventoryTableTrainsAddMutation} from './__generated__/InventoryTableTrainsAddMutation.graphql';
import {InventoryTableTrainsUpdateMutation} from './__generated__/InventoryTableTrainsUpdateMutation.graphql';
import {ConnectionHandler, GraphQLTaggedNode} from 'relay-runtime';
import {useAlertContext} from '@deckmans/web-shared';
import {useWindowSize} from 'react-use';

const useStyles = makeStyles(() => ({
  textFieldFont: {
    fontSize: '13px',
  },
}));

const fragment = graphql`
  fragment InventoryTableTrains_train on Viewer {
    trains(first: $count, after: $cursor) @connection(key: "Viewer_trains") {
      edges {
        node {
          id
          name
        }
      }
      total
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

interface Props {
  query: GraphQLTaggedNode;
  trainKey: InventoryTableTrains_train$key;
}

export function InventoryTableTrains({query, trainKey}: Props) {
  const classes = useStyles();
  const {alert} = useAlertContext();
  const {height} = useWindowSize();
  const [deleteTrain] = useMutation<InventoryTableTrainsDeleteMutation>(
    graphql`
      mutation InventoryTableTrainsDeleteMutation($input: TrainDeleteInput!) {
        trainDelete(input: $input) {
          id
        }
      }
    `,
    {
      updater(store, {trainDelete}) {
        if (trainDelete) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const trains = ConnectionHandler.getConnection(
              viewer,
              'Viewer_trains'
            );
            if (trains) {
              ConnectionHandler.deleteNode(trains, trainDelete.id);
            }
          }
        }
      },
    }
  );

  const [addTrain] = useMutation<InventoryTableTrainsAddMutation>(
    graphql`
      mutation InventoryTableTrainsAddMutation($input: TrainCreateInput!) {
        trainCreate(input: $input) {
          train {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {trainCreate}) {
        if (trainCreate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const trains = ConnectionHandler.getConnection(
              viewer,
              'Viewer_trains'
            );
            if (trains) {
              const train = store.get(trainCreate.train.id);

              if (train) {
                const edge = ConnectionHandler.createEdge(
                  store,
                  trains,
                  train,
                  'TrainEdge'
                );
                ConnectionHandler.insertEdgeAfter(trains, edge);
              }
            }
          }
        }
      },
    }
  );

  const [updateTrain] = useMutation<InventoryTableTrainsUpdateMutation>(
    graphql`
      mutation InventoryTableTrainsUpdateMutation($input: TrainUpdateInput!) {
        trainUpdate(input: $input) {
          train {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {trainUpdate}) {
        if (trainUpdate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const trains = ConnectionHandler.getConnection(
              viewer,
              'Viewer_trains'
            );
            if (trains) {
              const train = store.get(trainUpdate.train.id);
              if (train) {
                ConnectionHandler.createEdge(store, trains, train, 'TrainEdge');
              }
            }
          }
        }
      },
    }
  );

  const connectionConfig: ConnectionConfig = {
    getVariables(_props, {count, cursor}, _fv) {
      return {
        count,
        cursor,
      };
    },
    query,
  };

  const [train, pf] = usePagination(fragment, trainKey);

  const {page, pagedData, setPage} = useRelayPagination(
    train.trains.total ?? 0,
    10,
    pf,
    connectionConfig,
    train.trains
  );

  const trainTableData = React.useMemo(() => {
    const tableData: {
      id: string;
      name: string;
    }[] = [];

    pagedData.forEach((train) => {
      if (train) {
        tableData.push({
          id: train.id,
          name: train.name,
        });
      }
    });
    return tableData;
  }, [pagedData]);

  return (
    <>
      <MaterialTable
        data={trainTableData}
        options={{
          search: false,
          pageSize: 10,
          headerStyle: {position: 'sticky', top: 0},
          maxBodyHeight: height - 300,
          showTitle: false,
        }}
        columns={[
          {
            title: 'Name',
            field: 'name',
            validate: function validate(rowData) {
              if (!rowData.name) {
                return false;
              } else {
                if (rowData.name.trim() === '') {
                  return false;
                } else {
                  return true;
                }
              }
            },
            editComponent: function editComp(tableData) {
              const {columnDef, rowData, onRowDataChange, ...props} = tableData;
              return (
                <TextField
                  {...props}
                  value={tableData.rowData.name || ''}
                  inputProps={{autoFocus: true}}
                  placeholder="Name"
                  onChange={(e) => props.onChange(e.target.value)}
                  InputProps={{className: classes.textFieldFont}}
                />
              );
            },
          },
        ]}
        icons={{
          Add: React.forwardRef(function addCircle(props, ref) {
            return (
              <AddCircleIcon
                color="secondary"
                {...props}
                ref={ref}
                onClick={() => {}}
              />
            );
          }),

          Edit: React.forwardRef(function editCircle(props, ref) {
            return <EditRoundedIcon color="primary" {...props} ref={ref} />;
          }),

          Delete: React.forwardRef(function deleteCircle(props, ref) {
            return <DeleteRoundedIcon color="primary" {...props} ref={ref} />;
          }),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await addTrain({
                    variables: {
                      input: {
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowUpdate: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await updateTrain({
                    variables: {
                      input: {
                        id: newData.id,
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                try {
                  const deleteResponse = deleteTrain({
                    variables: {input: {id: oldData.id}},
                  });
                  if (deleteResponse) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),
        }}
        components={{
          Pagination: function paginationComp(props) {
            return (
              <TablePagination
                {...props}
                rowsPerPageOptions={[]}
                rowsPerPage={10}
                count={train.trains.total}
                page={page}
                onChangePage={(_e, page) => {
                  setPage(page);
                }}
              />
            );
          },
          Container: function containerComp(props) {
            return <Paper {...props} elevation={0} />;
          },
        }}
      />
    </>
  );
}
