/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type StockpileCreateInput = {
    name: string;
    clientMutationId?: string | null;
};
export type InventoryTableStockpilesAddMutationVariables = {
    input: StockpileCreateInput;
};
export type InventoryTableStockpilesAddMutationResponse = {
    readonly stockpileCreate: {
        readonly stockpile: {
            readonly id: string;
            readonly name: string;
        };
    } | null;
};
export type InventoryTableStockpilesAddMutation = {
    readonly response: InventoryTableStockpilesAddMutationResponse;
    readonly variables: InventoryTableStockpilesAddMutationVariables;
};



/*
mutation InventoryTableStockpilesAddMutation(
  $input: StockpileCreateInput!
) {
  stockpileCreate(input: $input) {
    stockpile {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "StockpileCreateInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "StockpileCreatePayload",
    "kind": "LinkedField",
    "name": "stockpileCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Stockpile",
        "kind": "LinkedField",
        "name": "stockpile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InventoryTableStockpilesAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InventoryTableStockpilesAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InventoryTableStockpilesAddMutation",
    "operationKind": "mutation",
    "text": "mutation InventoryTableStockpilesAddMutation(\n  $input: StockpileCreateInput!\n) {\n  stockpileCreate(input: $input) {\n    stockpile {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9b5ce187ce0206c1363773906d8381ff';
export default node;
