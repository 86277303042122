import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {Formik} from 'formik';
import {FTextField, useAlertContext} from '@deckmans/web-shared';
import {useMutation} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {AddNewActivityDialogAddMutation} from './__generated__/AddNewActivityDialogAddMutation.graphql';
import {AddNewActivityDialogCheckOptionMutation} from './__generated__/AddNewActivityDialogCheckOptionMutation.graphql';
import * as yup from 'yup';

interface Props {
  open: boolean;
  setOpen: () => void;
  decisionId: string;
  label: string;
  parentId: string | null;
  sequence: number | null;
}

const validationSchema = yup.object().shape({
  activity: yup.string().required('This field is required'),
});

export function AddNewActivityDialog({
  open,
  setOpen,
  decisionId,
  label,
  parentId,
  sequence,
}: Props) {
  const {alert} = useAlertContext();
  const [addDecision] = useMutation<AddNewActivityDialogAddMutation>(
    graphql`
      mutation AddNewActivityDialogAddMutation(
        $input: DecisionOptionCreateInput!
      ) {
        decisionOptionCreate(input: $input) {
          decisionTypeId
          decisionOption {
            id
            name
            parentId
            label
            key
            sequence
          }
        }
      }
    `,
    {
      updater(store, {decisionOptionCreate}) {
        if (decisionOptionCreate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const decOption = store.get(decisionOptionCreate.decisionOption.id);
            const workingEdge = store.get(decisionOptionCreate.decisionTypeId);
            const options = workingEdge?.getLinkedRecords('options');

            if (decOption && options) {
              options?.push(decOption);
              workingEdge?.setLinkedRecords(options, 'options');
            }
          }
        }
      },
    }
  );

  const [checkDecisionKey] = useMutation<
    AddNewActivityDialogCheckOptionMutation
  >(
    graphql`
      mutation AddNewActivityDialogCheckOptionMutation(
        $input: DecisionOptionCheckKeyInput!
      ) {
        decisionOptionCheckKey(input: $input) {
          exist
        }
      }
    `
  );

  async function checkDecKey(
    decisionKey: string,
    decisionId: string,
    activity: string,
    label: string,
    parentId: string | null
  ) {
    let keyExist = false;
    const count = 1;
    let decKey = decisionKey;
    try {
      const response = await checkDecisionKey({
        variables: {
          input: {
            key: decisionKey,
          },
        },
      });
      if (response) {
        if (response.decisionOptionCheckKey?.exist === true) {
          keyExist = true;
          decKey = decisionKey + count;
          checkDecKey(decKey, decisionId, activity, label, parentId);
        }
      } else {
        alert('Something went wrong', 'error');
      }
    } catch (error) {
      alert('Something went wrong', 'error');
    }

    if (keyExist === false) {
      try {
        const response = await addDecision({
          variables: {
            input: {
              decisionId,
              freeText: false,
              key: decKey,
              label,
              name: activity,
              parentId: parentId,
              sequence,
            },
          },
        });
        if (!response) {
          alert('Something went wrong', 'error');
        }
      } catch (error) {
        alert('Something went wrong', 'error');
      }
    }
  }

  return (
    <Formik
      initialValues={{activity: ''}}
      validationSchema={validationSchema}
      onSubmit={async (values, {resetForm}) => {
        const decisionKey = values.activity.replace(/ /g, '_').toUpperCase();

        checkDecKey(decisionKey, decisionId, values.activity, label, parentId);

        setOpen();
        resetForm();
      }}
    >
      {({submitForm}) => (
        <Dialog
          open={open}
          onClose={() => {
            setOpen();
          }}
          fullWidth
        >
          <DialogTitle>
            <Typography>Enter {label} name</Typography>
          </DialogTitle>
          <DialogContent>
            <FTextField
              fullWidth
              field="activity"
              placeholder={label}
              label={label}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              fullWidth
              onClick={() => {
                submitForm();
              }}
            >
              Add
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setOpen();
              }}
              variant="text"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
