import {
  Chip,
  makeStyles,
  MenuItem,
  Radio,
  Select,
  TablePagination,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {ConnectionConfig, useFragment, usePagination} from 'relay-hooks';
import {getUserRole, userRoles} from '@deckmans/domain/lib/util/enumData';
import {UserRole} from '@deckmans/domain';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {ConnectionHandler, GraphQLTaggedNode} from 'relay-runtime';
import {useAlertContext} from '@deckmans/web-shared';
import {UserRolesTable_users$key} from './__generated__/UserRolesTable_users.graphql';
import {useRelayPagination} from 'hooks/useRelayPagination';
import {useWindowSize} from 'react-use';
import {UserRolesTable_client$key} from './__generated__/UserRolesTable_client.graphql';
import {useUserCreateMutation} from './mutations/useUserCreateMutation';
import {useUserUpdateMutation} from './mutations/useUserUpdateMutation';
import {useUserDeleteMutation} from './mutations/useUserDeleteMutation';
import {useAuthContext} from 'contexts/AuthContext';
import {useUserLockAccountMutation} from './mutations/useUserLockAccountMutation';
import {useUserUnlockAccountMutation} from './mutations/useUserUnlockAccountMutation';
import {error} from 'relayError';
import {UserInfo} from './components/UserInfo';
import {UserRolesTable_user} from './__generated__/UserRolesTable_user.graphql';

const useStyles = makeStyles((theme: Theme) => ({
  roleSelect: {
    maxWidth: '150px',
    textOverflow: 'ellipsis',
  },
  roleSelectError: {
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    '&.MuiInput-underline:before': {
      borderColor: theme.palette.error.main,
    },
  },
  textFieldFont: {
    fontSize: '13px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

interface Props {
  query: GraphQLTaggedNode;
  paginationKey: UserRolesTable_users$key;
  clientPaginationKey: UserRolesTable_client$key;
}

function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email)) {
    return true;
  } else {
    return false;
  }
}

const fragment = graphql`
  fragment UserRolesTable_users on Viewer {
    users(first: $count, after: $cursor) @connection(key: "Viewer_users") {
      edges {
        node {
          ...UserRolesTable_user @relay(mask: false)
        }
      }
      total
      pageInfo {
        hasNextPage
      }
    }
  }
`;
graphql`
  fragment UserRolesTable_user on User {
    id
    username
    roles
    account {
      locked
    }
    clients {
      id
      name
    }
    profile {
      email
      name
      surname
      active
    }
    ...UserInfo_user
  }
`;

const clientFragment = graphql`
  fragment UserRolesTable_client on Viewer {
    clients(first: $count, after: $cursor) @connection(key: "Viewer_clients") {
      edges {
        node {
          id
          name
        }
      }
      total
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export function UserRolesTable({
  query,
  paginationKey,
  clientPaginationKey,
}: Props) {
  const classes = useStyles();
  const {alert} = useAlertContext();
  const {height} = useWindowSize();
  const {handleRequestResetPassword} = useAuthContext();

  const connectionConfig: ConnectionConfig = {
    getVariables(_props, {count, cursor}, _fv) {
      return {
        count,
        cursor,
      };
    },
    query,
  };

  const [user, pf] = usePagination(fragment, paginationKey);

  const clients = useFragment(clientFragment, clientPaginationKey);

  const {page, pagedData, setPage} = useRelayPagination(
    user.users?.total ?? 0,
    10,
    pf,
    connectionConfig,
    user.users
  );
  const [lockUser] = useUserLockAccountMutation({
    updater(store, {userLockAccount}) {
      if (userLockAccount) {
        const viewer = store.getRoot().getLinkedRecord('viewer');
        if (viewer) {
          const users = ConnectionHandler.getConnection(viewer, 'Viewer_users');
          if (users) {
            const user = store.get(userLockAccount.user.id);
            if (user) {
              ConnectionHandler.createEdge(store, users, user, 'UserEdge');
            }
          }
        }
      }
    },
  });
  const [unLockUser] = useUserUnlockAccountMutation({
    updater(store, {userUnlockAccount}) {
      if (userUnlockAccount) {
        const viewer = store.getRoot().getLinkedRecord('viewer');
        if (viewer) {
          const users = ConnectionHandler.getConnection(viewer, 'Viewer_users');
          if (users) {
            const user = store.get(userUnlockAccount.user.id);
            if (user) {
              ConnectionHandler.createEdge(store, users, user, 'UserEdge');
            }
          }
        }
      }
    },
  });

  const [deleteUser] = useUserDeleteMutation({
    updater(store, {userDelete}) {
      if (userDelete) {
        const viewer = store.getRoot().getLinkedRecord('viewer');
        if (viewer) {
          const users = ConnectionHandler.getConnection(viewer, 'Viewer_users');
          if (users) {
            ConnectionHandler.deleteNode(users, userDelete.id);
          }
        }
      }
    },
  });

  const [addUser] = useUserCreateMutation({
    updater(store, {userCreate}) {
      if (userCreate) {
        const viewer = store.getRoot().getLinkedRecord('viewer');
        if (viewer) {
          const users = ConnectionHandler.getConnection(viewer, 'Viewer_users');
          if (users) {
            const user = store.get(userCreate.user.id);

            if (user) {
              const edge = ConnectionHandler.createEdge(
                store,
                users,
                user,
                'UserEdge'
              );

              ConnectionHandler.insertEdgeAfter(users, edge);
            }
          }
        }
      }
    },
  });

  const [updateUser] = useUserUpdateMutation({
    updater(store, {userUpdate}) {
      if (userUpdate) {
        const viewer = store.getRoot().getLinkedRecord('viewer');
        if (viewer) {
          const users = ConnectionHandler.getConnection(viewer, 'Viewer_users');
          if (users) {
            const user = store.get(userUpdate.user.id);
            if (user) {
              ConnectionHandler.createEdge(store, users, user, 'UserEdge');
            }
          }
        }
      }
    },
  });

  const tableData = React.useMemo(() => {
    const tableData: {
      username: string;
      role?: number[];
      name: string;
      surname: string;
      email: string;
      id: string;
      active: boolean;
      archive: boolean;
      clients: string[];
      accountBlocked: boolean;
      key: UserRolesTable_user;
    }[] = [];

    pagedData.forEach((user) => {
      if (user) {
        tableData.push({
          username: user.username,
          role: user.roles.map((role) => role),
          name: user.profile.name,
          surname: user.profile.surname,
          email: user.profile.email,
          id: user.id,
          active: user.profile.active,
          archive: !user.profile.active,
          clients: user.clients.map((c) => c.id),
          accountBlocked: user.account.locked,
          key: user as UserRolesTable_user,
        });
      }
    });
    return tableData;
  }, [pagedData]);

  return (
    <>
      <MaterialTable<{
        username: string;
        role?: number[];
        name: string;
        surname: string;
        email: string;
        id: string;
        active: boolean;
        archive: boolean;
        clients: string[];
        accountBlocked: boolean;
        key: UserRolesTable_user;
      }>
        data={tableData}
        options={{
          search: false,
          pageSize: 10,
          headerStyle: {position: 'sticky', top: 0},
          maxBodyHeight: height - 230,
        }}
        title="Users"
        icons={{
          Add: React.forwardRef(function addCircle(props, ref) {
            return <AddCircleIcon color="secondary" {...props} ref={ref} />;
          }),

          Edit: React.forwardRef(function editCircle(props, ref) {
            return <EditRoundedIcon color="primary" {...props} ref={ref} />;
          }),

          Delete: React.forwardRef(function deleteCircle(props, ref) {
            return <DeleteRoundedIcon color="primary" {...props} ref={ref} />;
          }),
        }}
        columns={[
          {
            title: 'User Name',
            field: 'username',
            validate: function validate(rowData) {
              if (!rowData.username) {
                return false;
              } else {
                if (rowData.username.trim() === '') {
                  return false;
                } else {
                  return true;
                }
              }
            },
            editComponent: function editComp({onChange, rowData}) {
              return (
                <TextField
                  value={rowData.username}
                  inputProps={{autoFocus: true}}
                  placeholder="Username"
                  onChange={(e) => onChange(e.target.value)}
                  InputProps={{className: classes.textFieldFont}}
                />
              );
            },
          },
          {
            title: 'Name',
            field: 'name',
            validate: function validate(rowData) {
              if (!rowData.name) {
                return false;
              } else {
                if (rowData.name.trim() === '') {
                  return false;
                } else {
                  return true;
                }
              }
            },
          },
          {
            title: 'Surname',
            field: 'surname',
            validate: function validate(rowData) {
              if (!rowData.surname) {
                return false;
              } else {
                if (rowData.surname.trim() === '') {
                  return false;
                } else {
                  return true;
                }
              }
            },
          },
          {
            title: 'Role',
            field: 'role',
            cellStyle: {
              maxWidth: '150px',
              minWidth: '150px',
              overflow: 'hidden',
            },

            validate: function validate(rowData) {
              return rowData.role != null ? true : false;
            },
            render: (rowData) =>
              rowData.role?.map((role) => {
                const name = getUserRole(role as UserRole).description;
                return (
                  <Typography key={`${rowData.id}_${role}`} variant="body2">
                    {name}
                  </Typography>
                );
              }),
            editComponent: function editComp({
              rowData,
              onChange,
              onRowDataChange,
            }) {
              const {clients: rowClients, role, ...rest} = rowData;
              const value = rowData.role ?? [];
              return (
                <Select
                  variant="outlined"
                  fullWidth
                  defaultValue={UserRole.USER_ROLE_USER}
                  value={value}
                  onChange={(e) => {
                    onChange(e.target.value);
                    const newClients = clients.clients.edges.map(
                      (c) => c.node.id
                    );

                    if (
                      e.target.value === UserRole.USER_ROLE_ADMIN ||
                      e.target.value === UserRole.USER_ROLE_SUPERVISOR
                    ) {
                      onRowDataChange({
                        clients: newClients,
                        role: [e.target.value],
                        ...rest,
                      });
                    } else if (
                      e.target.value === UserRole.USER_ROLE_USER ||
                      e.target.value === UserRole.USER_ROLE_AGENT
                    ) {
                      onRowDataChange({
                        clients: [],
                        role: [e.target.value],
                        ...rest,
                      });
                    }
                  }}
                  className={`${
                    value.length === 0
                      ? classes.roleSelectError
                      : classes.roleSelect
                  }`}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {userRoles.map((roles) => {
                    return (
                      <MenuItem key={roles.id} value={roles.id}>
                        {roles.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              );
            },
          },

          {
            title: 'Clients',
            field: 'clients',
            cellStyle: {
              maxWidth: '150px',
              minWidth: '150px',
              overflow: 'hidden',
            },

            validate: (rowData) => rowData.clients != null,
            render: (rowData) =>
              rowData.clients?.map((client) => {
                return (
                  <Typography key={`${rowData.id}_${client}`} variant="body2">
                    {
                      clients.clients.edges.find((e) => e.node.id === client)
                        ?.node.name
                    }
                  </Typography>
                );
              }),
            editComponent: function editComp({rowData, onChange}) {
              const value = rowData.clients ?? [];

              return (
                <Select
                  variant="outlined"
                  fullWidth
                  value={value}
                  multiple
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  className={`${
                    value.length === 0
                      ? classes.roleSelectError
                      : classes.roleSelect
                  }`}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {(selected as string[]).map((value) => (
                        <Chip
                          key={value}
                          label={
                            clients.clients.edges.find(
                              (e) => e.node.id === value
                            )?.node.name
                          }
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                >
                  {clients.clients.edges.map((client) => {
                    return (
                      <MenuItem key={client.node.id} value={client.node.id}>
                        {client.node.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              );
            },
          },

          {
            title: 'Active',
            field: 'active',

            editComponent: function editComp({rowData, onChange}) {
              return (
                <Radio
                  name="active"
                  checked={rowData.active}
                  onChange={(e) => {
                    onChange(e.target.checked);
                  }}
                />
              );
            },
            initialEditValue: true,
            render: function renderComp(rowData) {
              return <Radio checked={rowData.active} disabled />;
            },
          },
          {
            title: 'Archive',
            field: 'active',
            initialEditValue: false,
            editComponent: function editComp({rowData, onChange}) {
              return (
                <Radio
                  name="active"
                  checked={!rowData.active}
                  onChange={(e) => {
                    onChange(!e.target.checked);
                  }}
                />
              );
            },
            render: function renderComp(rowData) {
              return <Radio checked={!rowData.active} disabled />;
            },
          },
          {
            title: 'Email',
            field: 'email',
            validate: function validate(rowData) {
              const validEmail = validateEmail(rowData.email);
              if (rowData.email === '' || !rowData.email) {
                return true;
              } else {
                if (validEmail) {
                  return true;
                } else {
                  return false;
                }
              }
            },
          },
        ]}
        actions={[
          (rowData) => ({
            icon: function iconComp() {
              return <UserInfo userKey={rowData.key} />;
            },
            tooltip: `View Account Information`,
            onClick: async (_event) => {
              //TODO open popper
            },
          }),

          (rowData) => ({
            icon: function iconComp() {
              if (rowData.accountBlocked) {
                return <LockIcon color="primary" />;
              }
              return <LockOpenIcon color="primary" />;
            },
            tooltip: `${
              rowData.accountBlocked ? 'Un Block' : 'Block'
            } User Account`,
            onClick: async (_event) => {
              const locked = rowData.accountBlocked;
              if (locked) {
                await unLockUser({
                  variables: {
                    input: {
                      userId: rowData.id,
                    },
                  },
                  onCompleted: () => {
                    alert('User account successfully unlocked', 'success');
                  },
                });
              } else {
                await lockUser({
                  variables: {
                    input: {
                      userId: rowData.id,
                    },
                  },
                  onCompleted: () => {
                    alert('User account successfully locked', 'success');
                  },
                });
              }
            },
          }),
          (rowData) => ({
            icon: function iconComp() {
              return <MailOutlineIcon color="primary" />;
            },
            tooltip: 'Send Change password link',
            onClick: async (_event) => {
              try {
                await handleRequestResetPassword({
                  username: rowData.username,
                  auth: true,
                });
                alert('Reset password email sent to user', 'success');
              } catch (e) {
                alert(e.message, 'error');
              }
            },
          }),
        ]}
        editable={{
          onRowAdd: async (newData) =>
            // Dont remove promise hook not creating correct promise
            new Promise((resolve, reject) =>
              addUser({
                variables: {
                  input: {
                    username: newData.username,
                    email: newData.email ? newData.email : '',
                    name: newData.name,
                    surname: newData.surname,
                    roles: newData.role,
                    active: newData.active === true ? newData.active : false,
                    clients: newData.clients,
                  },
                },
                onCompleted: (r) => {
                  alert('User created successfully', 'success');
                  resolve(r);
                },
                onError: (e) => {
                  alert(error(e), 'error');
                  reject(e);
                },
              })
            ),

          onRowUpdate: (newData) =>
            new Promise((resolve, reject) =>
              updateUser({
                variables: {
                  input: {
                    id: newData.id,
                    username: newData.username,
                    email: newData.email,
                    name: newData.name,
                    surname: newData.surname,
                    roles: newData.role,
                    active: newData.active === true ? newData.active : false,
                    clients: newData.clients,
                  },
                },
                onCompleted: (r) => {
                  alert('User edited successfully', 'success');
                  resolve(r);
                },
                onError: (e) => {
                  alert(error(e), 'error');
                  reject(e);
                },
              })
            ),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) =>
              deleteUser({
                variables: {input: {id: oldData.id}},
                onCompleted: (r) => {
                  alert('User deleted successfully', 'success');
                  resolve(r);
                },
                onError: (e) => {
                  alert(error(e), 'error');
                  reject(e);
                },
              })
            ),
        }}
        components={{
          Pagination: function paginationComp(props) {
            return (
              <TablePagination
                {...props}
                rowsPerPageOptions={[]}
                rowsPerPage={10}
                count={user.users?.total}
                page={page}
                onChangePage={(_e, page) => setPage(page)}
              />
            );
          },
        }}
      />
    </>
  );
}
