/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type OreTypeDeleteInput = {
    id: string;
    clientId: string;
    clientMutationId?: string | null;
};
export type InventoryTableOreTypesDeleteMutationVariables = {
    input: OreTypeDeleteInput;
};
export type InventoryTableOreTypesDeleteMutationResponse = {
    readonly oreTypeDelete: {
        readonly clientId: string;
        readonly id: string;
    } | null;
};
export type InventoryTableOreTypesDeleteMutation = {
    readonly response: InventoryTableOreTypesDeleteMutationResponse;
    readonly variables: InventoryTableOreTypesDeleteMutationVariables;
};



/*
mutation InventoryTableOreTypesDeleteMutation(
  $input: OreTypeDeleteInput!
) {
  oreTypeDelete(input: $input) {
    clientId
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "OreTypeDeleteInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OreTypeDeletePayload",
    "kind": "LinkedField",
    "name": "oreTypeDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InventoryTableOreTypesDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InventoryTableOreTypesDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InventoryTableOreTypesDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation InventoryTableOreTypesDeleteMutation(\n  $input: OreTypeDeleteInput!\n) {\n  oreTypeDelete(input: $input) {\n    clientId\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ab25bf5f883297b2866bbad5e0805752';
export default node;
