import {Mutate} from 'relay-hooks/lib/useMutation';
import {UpdateDecisionSequenceMutation} from './mutations/__generated__/UpdateDecisionSequenceMutation.graphql';
import {Color} from '@material-ui/lab/Alert';

export interface DecTypes {
  readonly id: string;
  readonly key: string;
  readonly options: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly freeText: boolean;
    readonly parentId: string | null;
    readonly label: string;
    readonly key: string;
    readonly sequence: number | null;
  }>;
}

export interface Options {
  option: string;
  options: {
    id: string;
    name: string;
    freeText: boolean;
    parentId: string | null;
    label: string;
    key: string;
    sequence: number | null;
  }[];
}

export interface OptionsInner {
  id: string;
  name: string;
  freeText: boolean;
  parentId: string | null;
  label: string;
  key: string;
  sequence: number | null;
}

export interface OptionType {
  readonly id: string;
  readonly name: string;
  readonly freeText: boolean;
  readonly parentId: string | null;
  readonly label: string;
  readonly key: string;
  readonly sequence: number | null;
}

export function UpdateDecisionSequenceUp(
  index: number,
  array: OptionsInner[],
  decisionId: string,
  setSelected: (val: number) => void,
  updateDecisionSequence: Mutate<UpdateDecisionSequenceMutation>,
  alert: (message: string, severity: Color | undefined) => void
) {
  const temp = array[index];

  const item1 = {
    ...temp,
    sequence: index,
  };
  const item2 = {
    ...array[index - 1],
    sequence: index + 1,
  };

  const newList = [item1, item2];

  newList.forEach(async (item) => {
    try {
      const response = await updateDecisionSequence({
        variables: {
          input: {
            optionId: item.id,
            decisionId,
            sequence: item.sequence,
          },
        },
      });
      if (!response) {
        alert('Something went wrong', 'error');
      }
    } catch (error) {
      alert('Something went wrong', 'error');
    }
    setSelected(index - 1);
  });
}

export function UpdateDecisionSequenceDown(
  index: number,
  array: OptionsInner[],
  decisionId: string,
  setSelected: (val: number) => void,
  updateDecisionSequence: Mutate<UpdateDecisionSequenceMutation>,
  alert: (message: string, severity: Color | undefined) => void
) {
  const temp = array[index];
  const item1 = {
    ...array[index + 1],
    sequence: index + 1,
  };
  const item2 = {
    ...temp,
    sequence: index + 2,
  };

  const newList = [item1, item2];

  newList.forEach(async (item) => {
    try {
      const response = await updateDecisionSequence({
        variables: {
          input: {
            optionId: item.id,
            decisionId,
            sequence: item.sequence,
          },
        },
      });
      if (!response) {
        alert('Something went wrong', 'error');
      }
    } catch (error) {
      alert('Something went wrong', 'error');
    }
    setSelected(index + 1);
  });
}
