import {useMutation} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {MutationConfig} from 'relay-hooks/lib/useMutation';
import {useUserDeleteMutation as useUserDeleteMutationType} from './__generated__/useUserDeleteMutation.graphql';

const fragment = graphql`
  mutation useUserDeleteMutation($input: UserDeleteInput!) {
    userDelete(input: $input) {
      clientMutationId
      id
    }
  }
`;
export const useUserDeleteMutation = (
  config?: MutationConfig<useUserDeleteMutationType>
) => useMutation<useUserDeleteMutationType>(fragment, config);
