/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type DecisionOptionUpdateSequenceInput = {
    optionId: string;
    decisionId?: string | null;
    sequence?: number | null;
    clientMutationId?: string | null;
};
export type UpdateDecisionSequenceMutationVariables = {
    input: DecisionOptionUpdateSequenceInput;
};
export type UpdateDecisionSequenceMutationResponse = {
    readonly decisionOptionUpdateSequence: {
        readonly decisionOption: {
            readonly id: string;
            readonly name: string;
            readonly sequence: number;
        };
    } | null;
};
export type UpdateDecisionSequenceMutation = {
    readonly response: UpdateDecisionSequenceMutationResponse;
    readonly variables: UpdateDecisionSequenceMutationVariables;
};



/*
mutation UpdateDecisionSequenceMutation(
  $input: DecisionOptionUpdateSequenceInput!
) {
  decisionOptionUpdateSequence(input: $input) {
    decisionOption {
      id
      name
      sequence
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "DecisionOptionUpdateSequenceInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DecisionOptionUpdateSequencePayload",
    "kind": "LinkedField",
    "name": "decisionOptionUpdateSequence",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DecisionOption",
        "kind": "LinkedField",
        "name": "decisionOption",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sequence",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateDecisionSequenceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateDecisionSequenceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "UpdateDecisionSequenceMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateDecisionSequenceMutation(\n  $input: DecisionOptionUpdateSequenceInput!\n) {\n  decisionOptionUpdateSequence(input: $input) {\n    decisionOption {\n      id\n      name\n      sequence\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'df878173c4b97b3cd75bdfb2a4be5d4a';
export default node;
