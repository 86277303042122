import {useMutation} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {useUserUnlockAccountMutation as useUserUnlockAccountMutationType} from './__generated__/useUserUnlockAccountMutation.graphql';
import {MutationConfig} from 'relay-hooks/lib/useMutation';

const fragment = graphql`
  mutation useUserUnlockAccountMutation($input: UserUnlockAccountInput!) {
    userUnlockAccount(input: $input) {
      user {
        ...UserRolesTable_user @relay(mask: false)
      }
    }
  }
`;
export const useUserUnlockAccountMutation = (
  config?: MutationConfig<useUserUnlockAccountMutationType>
) => useMutation<useUserUnlockAccountMutationType>(fragment, config);
