/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type ClientDeleteInput = {
    id: string;
    clientMutationId?: string | null;
};
export type InventoryTableClientsDeleteMutationVariables = {
    input: ClientDeleteInput;
};
export type InventoryTableClientsDeleteMutationResponse = {
    readonly clientDelete: {
        readonly id: string;
    } | null;
};
export type InventoryTableClientsDeleteMutation = {
    readonly response: InventoryTableClientsDeleteMutationResponse;
    readonly variables: InventoryTableClientsDeleteMutationVariables;
};



/*
mutation InventoryTableClientsDeleteMutation(
  $input: ClientDeleteInput!
) {
  clientDelete(input: $input) {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClientDeleteInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ClientDeletePayload",
    "kind": "LinkedField",
    "name": "clientDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InventoryTableClientsDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InventoryTableClientsDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InventoryTableClientsDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation InventoryTableClientsDeleteMutation(\n  $input: ClientDeleteInput!\n) {\n  clientDelete(input: $input) {\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '1b5e9394125f5eb2ef3af6b985ba92bd';
export default node;
