import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {ConnectionConfig, usePagination} from 'relay-hooks';
import {useRelayPagination} from 'hooks/useRelayPagination';
import MaterialTable from 'material-table';
import {makeStyles, Paper, TablePagination, TextField} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {GraphQLTaggedNode} from 'relay-runtime';
import {InventoryTableEqTypes_eqType$key} from './__generated__/InventoryTableEqTypes_eqType.graphql';
import {useWindowSize} from 'react-use';
import {InventoryTableEqNames} from './InventoryTableEqNames';
import {InventoryTableEqNames_eqNames$key} from './__generated__/InventoryTableEqNames_eqNames.graphql';

const useStyles = makeStyles(() => ({
  textFieldFont: {
    fontSize: '13px',
  },
}));

const fragment = graphql`
  fragment InventoryTableEqTypes_eqType on Viewer {
    equipmentTypes(first: $count, after: $cursor)
      @connection(key: "Viewer_equipmentTypes") {
      edges {
        node {
          id
          name
        }
      }
      total
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

interface Props {
  query: GraphQLTaggedNode;
  eqTypeKey: InventoryTableEqTypes_eqType$key;
  eqNameKey: InventoryTableEqNames_eqNames$key;
}

export function InventoryTableEqTypes({query, eqTypeKey, eqNameKey}: Props) {
  const classes = useStyles();
  const {height} = useWindowSize();

  const connectionConfig: ConnectionConfig = {
    getVariables(_props, {count, cursor}, _fv) {
      return {
        count,
        cursor,
      };
    },
    query,
  };

  const [eqTypes, pf] = usePagination(fragment, eqTypeKey);

  const {page, pagedData, setPage} = useRelayPagination(
    eqTypes.equipmentTypes.total ?? 0,
    10,
    pf,
    connectionConfig,
    eqTypes.equipmentTypes
  );

  const tableData = React.useMemo(() => {
    const tableData: {
      id: string;
      name: string;
    }[] = [];

    pagedData.forEach((eqType) => {
      if (eqType) {
        tableData.push({
          id: eqType.id,
          name: eqType.name,
        });
      }
    });
    return tableData;
  }, [pagedData]);

  return (
    <>
      <MaterialTable
        data={tableData}
        options={{
          search: false,
          pageSize: 10,
          headerStyle: {position: 'sticky', top: 0},
          maxBodyHeight: height - 300,
          showTitle: false,
        }}
        detailPanel={[
          {
            tooltip: 'Expand',
            render: function matTable(rowData) {
              return (
                eqTypes.equipmentTypes && (
                  <InventoryTableEqNames
                    query={query}
                    eqTypeId={rowData.id}
                    eqNameKey={eqNameKey}
                  />
                )
              );
            },
          },
        ]}
        columns={[
          {
            title: 'Name',
            field: 'name',
            validate: function validate(rowData) {
              if (!rowData.name) {
                return false;
              } else {
                if (rowData.name.trim() === '') {
                  return false;
                } else {
                  return true;
                }
              }
            },
            editComponent: function editComp(tableData) {
              const {columnDef, rowData, onRowDataChange, ...props} = tableData;
              return (
                <TextField
                  {...props}
                  value={tableData.rowData.name || ''}
                  inputProps={{autoFocus: true}}
                  placeholder="Name"
                  onChange={(e) => props.onChange(e.target.value)}
                  InputProps={{className: classes.textFieldFont}}
                />
              );
            },
          },
        ]}
        icons={{
          Add: React.forwardRef(function addCircle(props, ref) {
            return <AddCircleIcon color="secondary" {...props} ref={ref} />;
          }),

          Edit: React.forwardRef(function editCircle(props, ref) {
            return <EditRoundedIcon color="primary" {...props} ref={ref} />;
          }),

          Delete: React.forwardRef(function deleteCircle(props, ref) {
            return <DeleteRoundedIcon color="primary" {...props} ref={ref} />;
          }),
        }}
        components={{
          Pagination: function paginationComp(props) {
            return (
              <TablePagination
                {...props}
                rowsPerPageOptions={[]}
                rowsPerPage={10}
                count={eqTypes.equipmentTypes.total ?? 0}
                page={page}
                onChangePage={(_e, page) => {
                  setPage(page);
                }}
              />
            );
          },
          Container: function containerComp(props) {
            return <Paper {...props} elevation={0} />;
          },
        }}
      />
    </>
  );
}
