import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {ConnectionConfig, usePagination} from 'relay-hooks';
import {useRelayPagination} from 'hooks/useRelayPagination';
import MaterialTable from 'material-table';
import {makeStyles, Paper, TablePagination, TextField} from '@material-ui/core';
import {useMutation} from 'relay-hooks';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {ConnectionHandler, GraphQLTaggedNode} from 'relay-runtime';
import {InventoryTableStockpiles_stockpile$key} from './__generated__/InventoryTableStockpiles_stockpile.graphql';
import {InventoryTableStockpilesDeleteMutation} from './__generated__/InventoryTableStockpilesDeleteMutation.graphql';
import {InventoryTableStockpilesAddMutation} from './__generated__/InventoryTableStockpilesAddMutation.graphql';
import {InventoryTableStockpilesUpdateMutation} from './__generated__/InventoryTableStockpilesUpdateMutation.graphql';
import {useAlertContext} from '@deckmans/web-shared';
import {useWindowSize} from 'react-use';

const useStyles = makeStyles(() => ({
  textFieldFont: {
    fontSize: '13px',
  },
}));

const fragment = graphql`
  fragment InventoryTableStockpiles_stockpile on Viewer {
    stockpiles(first: $count, after: $cursor)
      @connection(key: "Viewer_stockpiles") {
      edges {
        node {
          id
          name
        }
      }
      total
      pageInfo {
        hasNextPage
      }
    }
  }
`;

interface Props {
  query: GraphQLTaggedNode;
  stockpileKey: InventoryTableStockpiles_stockpile$key;
}

export function InventoryTableStockpiles({query, stockpileKey}: Props) {
  const classes = useStyles();
  const {alert} = useAlertContext();
  const {height} = useWindowSize();
  const [deleteStockpile] = useMutation<InventoryTableStockpilesDeleteMutation>(
    graphql`
      mutation InventoryTableStockpilesDeleteMutation(
        $input: StockpileDeleteInput!
      ) {
        stockpileDelete(input: $input) {
          id
        }
      }
    `,
    {
      updater(store, {stockpileDelete}) {
        if (stockpileDelete) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const stockpiles = ConnectionHandler.getConnection(
              viewer,
              'Viewer_stockpiles'
            );
            if (stockpiles) {
              ConnectionHandler.deleteNode(stockpiles, stockpileDelete.id);
            }
          }
        }
      },
    }
  );

  const [addStockpile] = useMutation<InventoryTableStockpilesAddMutation>(
    graphql`
      mutation InventoryTableStockpilesAddMutation(
        $input: StockpileCreateInput!
      ) {
        stockpileCreate(input: $input) {
          stockpile {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {stockpileCreate}) {
        if (stockpileCreate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const stockpiles = ConnectionHandler.getConnection(
              viewer,
              'Viewer_stockpiles'
            );
            if (stockpiles) {
              const stockpile = store.get(stockpileCreate.stockpile.id);

              if (stockpile) {
                const edge = ConnectionHandler.createEdge(
                  store,
                  stockpiles,
                  stockpile,
                  'StockpileEdge'
                );
                ConnectionHandler.insertEdgeAfter(stockpiles, edge);
              }
            }
          }
        }
      },
    }
  );

  const [updateStockpile] = useMutation<InventoryTableStockpilesUpdateMutation>(
    graphql`
      mutation InventoryTableStockpilesUpdateMutation(
        $input: StockpileUpdateInput!
      ) {
        stockpileUpdate(input: $input) {
          stockpile {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {stockpileUpdate}) {
        if (stockpileUpdate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const stockpiles = ConnectionHandler.getConnection(
              viewer,
              'Viewer_stockpiles'
            );
            if (stockpiles) {
              const stockpile = store.get(stockpileUpdate.stockpile.id);
              if (stockpile) {
                ConnectionHandler.createEdge(
                  store,
                  stockpiles,
                  stockpile,
                  'StockpileEdge'
                );
              }
            }
          }
        }
      },
    }
  );

  const connectionConfig: ConnectionConfig = {
    getVariables(_props, {count, cursor}, _fv) {
      return {
        count,
        cursor,
      };
    },
    query,
  };

  const [stockpile, pf] = usePagination(fragment, stockpileKey);

  const {page, pagedData, setPage} = useRelayPagination(
    stockpile.stockpiles.total ?? 0,
    10,
    pf,
    connectionConfig,
    stockpile.stockpiles
  );

  const tableData = React.useMemo(() => {
    const tableData: {
      id: string;
      name: string;
    }[] = [];

    pagedData.forEach((stockpile) => {
      if (stockpile) {
        tableData.push({
          id: stockpile.id,
          name: stockpile.name,
        });
      }
    });
    return tableData;
  }, [pagedData]);

  return (
    <>
      <MaterialTable
        data={tableData}
        options={{
          search: false,
          pageSize: 10,
          headerStyle: {position: 'sticky', top: 0},
          maxBodyHeight: height - 300,
          showTitle: false,
        }}
        columns={[
          {
            title: 'Name',
            field: 'name',
            validate: function validate(rowData) {
              if (!rowData.name) {
                return false;
              } else {
                if (rowData.name.trim() === '') {
                  return false;
                } else {
                  return true;
                }
              }
            },
            editComponent: function editComp(tableData) {
              const {columnDef, rowData, onRowDataChange, ...props} = tableData;
              return (
                <TextField
                  {...props}
                  value={tableData.rowData.name || ''}
                  inputProps={{autoFocus: true}}
                  placeholder="Name"
                  onChange={(e) => props.onChange(e.target.value)}
                  InputProps={{className: classes.textFieldFont}}
                />
              );
            },
          },
        ]}
        icons={{
          Add: React.forwardRef(function addCircle(props, ref) {
            return <AddCircleIcon color="secondary" {...props} ref={ref} />;
          }),

          Edit: React.forwardRef(function editCircle(props, ref) {
            return <EditRoundedIcon color="primary" {...props} ref={ref} />;
          }),

          Delete: React.forwardRef(function deleteCircle(props, ref) {
            return <DeleteRoundedIcon color="primary" {...props} ref={ref} />;
          }),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await addStockpile({
                    variables: {
                      input: {
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowUpdate: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await updateStockpile({
                    variables: {
                      input: {
                        id: newData.id,
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                try {
                  const deleteResponse = deleteStockpile({
                    variables: {input: {id: oldData.id}},
                  });
                  if (deleteResponse) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),
        }}
        components={{
          Pagination: function paginationComp(props) {
            return (
              <TablePagination
                {...props}
                rowsPerPageOptions={[]}
                rowsPerPage={10}
                count={stockpile.stockpiles.total ?? 0}
                page={page}
                onChangePage={(_e, page) => {
                  setPage(page);
                }}
              />
            );
          },
          Container: function containerComp(props) {
            return <Paper {...props} elevation={0} />;
          },
        }}
      />
    </>
  );
}
