/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type TrainCreateInput = {
    name: string;
    clientMutationId?: string | null;
};
export type InventoryTableTrainsAddMutationVariables = {
    input: TrainCreateInput;
};
export type InventoryTableTrainsAddMutationResponse = {
    readonly trainCreate: {
        readonly train: {
            readonly id: string;
            readonly name: string;
        };
    } | null;
};
export type InventoryTableTrainsAddMutation = {
    readonly response: InventoryTableTrainsAddMutationResponse;
    readonly variables: InventoryTableTrainsAddMutationVariables;
};



/*
mutation InventoryTableTrainsAddMutation(
  $input: TrainCreateInput!
) {
  trainCreate(input: $input) {
    train {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "TrainCreateInput!"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TrainCreatePayload",
    "kind": "LinkedField",
    "name": "trainCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Train",
        "kind": "LinkedField",
        "name": "train",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InventoryTableTrainsAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InventoryTableTrainsAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "InventoryTableTrainsAddMutation",
    "operationKind": "mutation",
    "text": "mutation InventoryTableTrainsAddMutation(\n  $input: TrainCreateInput!\n) {\n  trainCreate(input: $input) {\n    train {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5bac21bfcfdae5ed98684bb0a36dc7d8';
export default node;
