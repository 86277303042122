import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {ConnectionConfig, usePagination} from 'relay-hooks';
import {useRelayPagination} from 'hooks/useRelayPagination';
import MaterialTable from 'material-table';
import {makeStyles, Paper, TextField} from '@material-ui/core';
import {useMutation} from 'relay-hooks';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {ConnectionHandler, GraphQLTaggedNode} from 'relay-runtime';
import {useAlertContext} from '@deckmans/web-shared';
import {InventoryTableEqNamesDeleteMutation} from './__generated__/InventoryTableEqNamesDeleteMutation.graphql';
import {InventoryTableEqNamesAddMutation} from './__generated__/InventoryTableEqNamesAddMutation.graphql';
import {InventoryTableEqNamesUpdateMutation} from './__generated__/InventoryTableEqNamesUpdateMutation.graphql';
import {InventoryTableEqNames_eqNames$key} from './__generated__/InventoryTableEqNames_eqNames.graphql';

const useStyles = makeStyles(() => ({
  textFieldFont: {
    fontSize: '13px',
  },
}));

const fragment = graphql`
  fragment InventoryTableEqNames_eqNames on Viewer {
    equipmentTypes(first: $count, after: $cursor)
      @connection(key: "Viewer_eqNames_equipmentTypes") {
      edges {
        node {
          id
          name
          equipmentNames {
            id
            name
          }
        }
      }
      total
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

interface Props {
  query: GraphQLTaggedNode;
  eqNameKey: InventoryTableEqNames_eqNames$key;
  eqTypeId: string;
}

export function InventoryTableEqNames({query, eqNameKey, eqTypeId}: Props) {
  const classes = useStyles();
  const {alert} = useAlertContext();

  const [deleteEquipmentName] = useMutation<
    InventoryTableEqNamesDeleteMutation
  >(
    graphql`
      mutation InventoryTableEqNamesDeleteMutation(
        $input: EquipmentNameDeleteInput!
      ) {
        equipmentNameDelete(input: $input) {
          equipmentTypeId
          id
        }
      }
    `,
    {
      updater(store, {equipmentNameDelete}) {
        if (equipmentNameDelete) {
          const viewer = store.getRoot().getLinkedRecord('viewer');

          if (viewer) {
            const eqNamesCon = ConnectionHandler.getConnection(
              viewer,
              'Viewer_eqNames_equipmentTypes'
            );

            if (eqNamesCon) {
              const eqName = store.get(equipmentNameDelete.id);

              const workingEdge = store.get(
                equipmentNameDelete.equipmentTypeId
              );
              const eqNames = workingEdge?.getLinkedRecords('equipmentNames');

              if (eqName && eqNames) {
                const eqNamesFiltered = eqNames?.filter((eqName) => {
                  return eqName.getDataID() !== equipmentNameDelete.id;
                });
                workingEdge?.setLinkedRecords(
                  eqNamesFiltered,
                  'equipmentNames'
                );
              }
            }
          }
        }
      },
    }
  );

  const [addEquipmentName] = useMutation<InventoryTableEqNamesAddMutation>(
    graphql`
      mutation InventoryTableEqNamesAddMutation(
        $input: EquipmentNameCreateInput!
      ) {
        equipmentNameCreate(input: $input) {
          equipmentTypeId
          equipmentName {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {equipmentNameCreate}) {
        if (equipmentNameCreate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const eqName = store.get(equipmentNameCreate.equipmentName.id);
            const workingEdge = store.get(equipmentNameCreate.equipmentTypeId);
            const eqNames = workingEdge?.getLinkedRecords('equipmentNames');

            if (eqName && eqNames) {
              eqNames?.push(eqName);
              workingEdge?.setLinkedRecords(eqNames, 'equipmentNames');
            }
          }
        }
      },
    }
  );

  const [updateEquipmentName] = useMutation<
    InventoryTableEqNamesUpdateMutation
  >(
    graphql`
      mutation InventoryTableEqNamesUpdateMutation(
        $input: EquipmentNameUpdateInput!
      ) {
        equipmentNameUpdate(input: $input) {
          equipmentName {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {equipmentNameUpdate}) {
        if (equipmentNameUpdate) {
          const payload = store.getRootField('equipmentNameUpdate');
          const eqName = payload.getLinkedRecord('equipmentName');
          const nameOfEq = eqName.getValue('name');
          const idOfEq = eqName.getValue('id');
          const newEqName = store.get(idOfEq);
          newEqName?.setValue(nameOfEq, 'name');
        }
      },
    }
  );

  const connectionConfig: ConnectionConfig = {
    getVariables(_props, {count, cursor}, _fv) {
      return {
        count,
        cursor,
      };
    },
    query,
  };

  const [eqTypes, pf] = usePagination(fragment, eqNameKey);

  const {pagedData} = useRelayPagination(
    eqTypes.equipmentTypes.total ?? 0,
    10,
    pf,
    connectionConfig,
    eqTypes.equipmentTypes
  );

  const tableData = React.useMemo(() => {
    const tableData: {
      eqTypeId: string;
      id: string;
      name: string;
    }[] = [];

    pagedData.forEach((eqType) => {
      if (eqType) {
        const equipmentTypeId = eqType.id;
        if (equipmentTypeId === eqTypeId) {
          eqType.equipmentNames.forEach((eqName) => {
            tableData.push({
              eqTypeId: equipmentTypeId,
              id: eqName.id,
              name: eqName.name,
            });
          });
        }
      }
    });
    return tableData;
  }, [pagedData, eqTypeId]);

  return (
    <>
      <MaterialTable
        data={tableData}
        options={{
          pageSize: 10,
          pageSizeOptions: [],
          headerStyle: {position: 'sticky', top: 0},
          emptyRowsWhenPaging: false,
          showTitle: false,
        }}
        columns={[
          {
            title: 'Equipment name',
            field: 'name',
            validate: function validate(rowData) {
              if (!rowData.name) {
                return false;
              } else {
                if (rowData.name.trim() === '') {
                  return false;
                } else {
                  return true;
                }
              }
            },
            editComponent: function editComp(tableData) {
              const {columnDef, rowData, onRowDataChange, ...props} = tableData;
              return (
                <TextField
                  {...props}
                  value={tableData.rowData.name || ''}
                  inputProps={{autoFocus: true}}
                  placeholder="Name"
                  onChange={(e) => props.onChange(e.target.value)}
                  InputProps={{className: classes.textFieldFont}}
                />
              );
            },
          },
        ]}
        icons={{
          Add: React.forwardRef(function addCircle(props, ref) {
            return <AddCircleIcon color="secondary" {...props} ref={ref} />;
          }),

          Edit: React.forwardRef(function editCircle(props, ref) {
            return <EditRoundedIcon color="primary" {...props} ref={ref} />;
          }),

          Delete: React.forwardRef(function deleteCircle(props, ref) {
            return <DeleteRoundedIcon color="primary" {...props} ref={ref} />;
          }),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await addEquipmentName({
                    variables: {
                      input: {
                        equipmentTypeId: eqTypeId,
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }

                reject();
              }, 1000);
            }),

          onRowUpdate: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await updateEquipmentName({
                    variables: {
                      input: {
                        id: newData.id,
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                try {
                  const deleteResponse = deleteEquipmentName({
                    variables: {
                      input: {id: oldData.id, equipmentTypeId: eqTypeId},
                    },
                  });
                  if (deleteResponse) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),
        }}
        components={{
          Container: function containerComp(props) {
            return <Paper {...props} elevation={0} />;
          },
        }}
      />
    </>
  );
}
