import React from 'react';
import {useTitle} from '@deckmans/web-shared';
import {InventoryTable} from './InventoryTable';
import {RelayRenderer} from 'modules/RelayRenderer/RelayRenderer';
import graphql from 'babel-plugin-relay/macro';

const query = graphql`
  query InventoryQuery($cursor: String, $count: Int = 10) {
    viewer {
      ...InventoryTableRoutes_route
      ...InventoryTableTrains_train
      ...InventoryTableStockpiles_stockpile
      ...InventoryTableEqTypes_eqType
      ...InventoryTableEqNames_eqNames
      ...InventoryTableTipplers_tippler
      ...InventoryTableClients_client
      ...InventoryTableOreTypes_oreTypes
    }
  }
`;

export function Inventory() {
  useTitle('Inventory');
  return (
    <RelayRenderer query={query}>
      {(props) => {
        return (
          <div>
            {props && props?.viewer && (
              <InventoryTable
                query={query}
                trainKey={props.viewer}
                stockpileKey={props.viewer}
                eqTypeKey={props.viewer}
                routeKey={props.viewer}
                eqNameKey={props.viewer}
                tipplerKey={props.viewer}
                clientKey={props.viewer}
                oreTypeKey={props.viewer}
              />
            )}
          </div>
        );
      }}
    </RelayRenderer>
  );
}
