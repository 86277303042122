import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {ConnectionConfig, usePagination} from 'relay-hooks';
import {useRelayPagination} from 'hooks/useRelayPagination';
import MaterialTable from 'material-table';
import {makeStyles, Paper, TablePagination, TextField} from '@material-ui/core';
import {useMutation} from 'relay-hooks';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {ConnectionHandler, GraphQLTaggedNode} from 'relay-runtime';
import {useAlertContext} from '@deckmans/web-shared';
import {useWindowSize} from 'react-use';
import {InventoryTableClients_client$key} from './__generated__/InventoryTableClients_client.graphql';
import {InventoryTableClientsDeleteMutation} from './__generated__/InventoryTableClientsDeleteMutation.graphql';
import {InventoryTableClientsAddMutation} from './__generated__/InventoryTableClientsAddMutation.graphql';
import {InventoryTableClientsUpdateMutation} from './__generated__/InventoryTableClientsUpdateMutation.graphql';
import {InventoryTableOreTypes} from './InventoryTableOreTypes';
import {InventoryTableOreTypes_oreTypes$key} from './__generated__/InventoryTableOreTypes_oreTypes.graphql';

const useStyles = makeStyles(() => ({
  textFieldFont: {
    fontSize: '13px',
  },
}));

const fragment = graphql`
  fragment InventoryTableClients_client on Viewer {
    clients(first: $count, after: $cursor) @connection(key: "Viewer_clients") {
      edges {
        node {
          id
          name
        }
      }
      total
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

interface Props {
  query: GraphQLTaggedNode;
  clientKey: InventoryTableClients_client$key;
  oreTypeKey: InventoryTableOreTypes_oreTypes$key;
}

export function InventoryTableClients({query, clientKey, oreTypeKey}: Props) {
  const classes = useStyles();
  const {alert} = useAlertContext();
  const {height} = useWindowSize();
  const [deleteClient] = useMutation<InventoryTableClientsDeleteMutation>(
    graphql`
      mutation InventoryTableClientsDeleteMutation($input: ClientDeleteInput!) {
        clientDelete(input: $input) {
          id
        }
      }
    `,
    {
      updater(store, {clientDelete}) {
        if (clientDelete) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const clients = ConnectionHandler.getConnection(
              viewer,
              'Viewer_clients'
            );
            if (clients) {
              ConnectionHandler.deleteNode(clients, clientDelete.id);
            }
          }
        }
      },
    }
  );

  const [addClient] = useMutation<InventoryTableClientsAddMutation>(
    graphql`
      mutation InventoryTableClientsAddMutation($input: ClientCreateInput!) {
        clientCreate(input: $input) {
          client {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {clientCreate}) {
        if (clientCreate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const clients = ConnectionHandler.getConnection(
              viewer,
              'Viewer_clients'
            );
            if (clients) {
              const client = store.get(clientCreate.client.id);

              if (client) {
                const edge = ConnectionHandler.createEdge(
                  store,
                  clients,
                  client,
                  'ClientEdge'
                );
                ConnectionHandler.insertEdgeAfter(clients, edge);
              }
            }
          }
        }
      },
    }
  );

  const [updateClient] = useMutation<InventoryTableClientsUpdateMutation>(
    graphql`
      mutation InventoryTableClientsUpdateMutation($input: ClientUpdateInput!) {
        clientUpdate(input: $input) {
          client {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {clientUpdate}) {
        if (clientUpdate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const clients = ConnectionHandler.getConnection(
              viewer,
              'Viewer_clients'
            );
            if (clients) {
              const client = store.get(clientUpdate.client.id);
              if (client) {
                ConnectionHandler.createEdge(
                  store,
                  clients,
                  client,
                  'ClientEdge'
                );
              }
            }
          }
        }
      },
      onCompleted() {
        refetch();
      },
    }
  );

  const connectionConfig: ConnectionConfig = {
    getVariables(_props, {count, cursor}, _fv) {
      return {
        count,
        cursor,
      };
    },
    query,
  };

  const [client, pf] = usePagination(fragment, clientKey);

  const {page, pagedData, setPage, refetch} = useRelayPagination(
    client.clients.total ?? 0,
    10,
    pf,
    connectionConfig,
    client.clients
  );

  const clientTableData = React.useMemo(() => {
    const tableData: {
      id: string;
      name: string;
    }[] = [];

    pagedData.forEach((client) => {
      if (client) {
        tableData.push({
          id: client.id,
          name: client.name,
        });
      }
    });
    return tableData;
  }, [pagedData]);

  return (
    <>
      <MaterialTable
        data={clientTableData}
        options={{
          search: false,
          pageSize: 10,
          headerStyle: {position: 'sticky', top: 0},
          maxBodyHeight: height - 300,
          showTitle: false,
        }}
        detailPanel={[
          {
            tooltip: 'Expand',
            render: function matTable(rowData) {
              return (
                client.clients && (
                  <InventoryTableOreTypes
                    query={query}
                    clientId={rowData.id}
                    oreTypeKey={oreTypeKey}
                  />
                )
              );
            },
          },
        ]}
        columns={[
          {
            title: 'Name',
            field: 'name',
            validate: function validate(rowData) {
              if (!rowData.name) {
                return false;
              } else {
                if (rowData.name.trim() === '') {
                  return false;
                } else {
                  return true;
                }
              }
            },
            editComponent: function editComp(tableData) {
              const {columnDef, rowData, onRowDataChange, ...props} = tableData;
              return (
                <TextField
                  {...props}
                  value={tableData.rowData.name || ''}
                  inputProps={{autoFocus: true}}
                  placeholder="Name"
                  onChange={(e) => props.onChange(e.target.value)}
                  InputProps={{className: classes.textFieldFont}}
                />
              );
            },
          },
        ]}
        icons={{
          Add: React.forwardRef(function addCircle(props, ref) {
            return (
              <AddCircleIcon
                color="secondary"
                {...props}
                ref={ref}
                onClick={() => {}}
              />
            );
          }),

          Edit: React.forwardRef(function editCircle(props, ref) {
            return <EditRoundedIcon color="primary" {...props} ref={ref} />;
          }),

          Delete: React.forwardRef(function deleteCircle(props, ref) {
            return <DeleteRoundedIcon color="primary" {...props} ref={ref} />;
          }),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await addClient({
                    variables: {
                      input: {
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert(error[0].message, 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowUpdate: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await updateClient({
                    variables: {
                      input: {
                        id: newData.id,
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                try {
                  const deleteResponse = deleteClient({
                    variables: {input: {id: oldData.id}},
                  });
                  if (deleteResponse) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),
        }}
        components={{
          Pagination: function paginationComp(props) {
            return (
              <TablePagination
                {...props}
                rowsPerPageOptions={[]}
                rowsPerPage={10}
                count={client.clients.total ?? 0}
                page={page}
                onChangePage={(_e, page) => {
                  setPage(page);
                }}
              />
            );
          },
          Container: function containerComp(props) {
            return <Paper {...props} elevation={0} />;
          },
        }}
      />
    </>
  );
}
