import React from 'react';
import {useTitle} from '@deckmans/web-shared';
import graphql from 'babel-plugin-relay/macro';
import {RelayRenderer} from 'modules/RelayRenderer/RelayRenderer';
import {LogbookBuilderTable} from './LogbookBuilderTable';

const query = graphql`
  query LogbookBuilderQuery {
    viewer {
      master {
        ...LogbookBuilderTable_decisions
      }
    }
  }
`;

export function LogbookBuilder() {
  useTitle('Logbook Builder');
  return (
    <RelayRenderer query={query}>
      {(props) => {
        return (
          <div>
            {props && props.viewer && props.viewer?.master && (
              <LogbookBuilderTable fragmentKey={props.viewer.master} />
            )}
          </div>
        );
      }}
    </RelayRenderer>
  );
}
