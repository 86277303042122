/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LogbookBuilderQueryVariables = {};
export type LogbookBuilderQueryResponse = {
    readonly viewer: {
        readonly master: {
            readonly " $fragmentRefs": FragmentRefs<"LogbookBuilderTable_decisions">;
        };
    } | null;
};
export type LogbookBuilderQuery = {
    readonly response: LogbookBuilderQueryResponse;
    readonly variables: LogbookBuilderQueryVariables;
};



/*
query LogbookBuilderQuery {
  viewer {
    master {
      ...LogbookBuilderTable_decisions
    }
  }
}

fragment LogbookBuilderTable_decisions on Master {
  decisions {
    id
    key
    options {
      id
      name
      freeText
      parentId
      label
      key
      sequence
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LogbookBuilderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Master",
            "kind": "LinkedField",
            "name": "master",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LogbookBuilderTable_decisions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LogbookBuilderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Master",
            "kind": "LinkedField",
            "name": "master",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Decision",
                "kind": "LinkedField",
                "name": "decisions",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DecisionOption",
                    "kind": "LinkedField",
                    "name": "options",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "freeText",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "parentId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sequence",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "LogbookBuilderQuery",
    "operationKind": "query",
    "text": "query LogbookBuilderQuery {\n  viewer {\n    master {\n      ...LogbookBuilderTable_decisions\n    }\n  }\n}\n\nfragment LogbookBuilderTable_decisions on Master {\n  decisions {\n    id\n    key\n    options {\n      id\n      name\n      freeText\n      parentId\n      label\n      key\n      sequence\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fee3d54610a5566b584edebb89a427e1';
export default node;
