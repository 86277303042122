import * as React from 'react';
import {Route, Switch, Redirect, RouteProps} from 'react-router';
import {ThemeSpecifics} from './modules/ThemeSpecifics/ThemeSpecifics';
import {AuthContext, useAuthContext} from 'contexts/AuthContext';
import {LoginPage} from './modules/LoginPage/LoginPage';
import {Page404} from 'modules/404/Page404';
import {makeStyles, Theme} from '@material-ui/core';
import {LogbookBuilder} from 'modules/LogbookBuilder/LogbookBuilder';
import {Inventory} from 'modules/Inventory/Inventory';
import {UserRoles} from 'modules/UserRoles/UserRoles';
import {Homepage} from 'modules/Homepage/Homepage';
import {AdminReload} from 'modules/AdminReload/AdminReload';
import {AdminProjectionsRecreate} from 'modules/AdminProjectionsRecreate/AdminProjectionsRecreate';
import useWindowDimensions from 'hooks/useWindowDimensions';
import {Test} from 'modules/Test/Test';
import {CreatePassword} from 'modules/CreatePassword/CreatePassword';
import {ResetPassword} from 'modules/ResetPassword/ResetPassword';
import {TokenExpired} from 'modules/TokenExpired/TokenExpired';

function PrivateRoute({...props}: Omit<RouteProps, 'children' | ''>) {
  const {auth} = React.useContext(AuthContext);

  if (!auth.authenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {from: props.location},
        }}
      />
    );
  }

  return <Route {...props} />;
}

interface WrapperProps {
  children: React.ReactNode;
}
function Wrapper({children}: WrapperProps) {
  const {width} = useWindowDimensions();
  const {auth} = useAuthContext();

  const useStyles = makeStyles<Theme, {isLogin: boolean}>((theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      margin: ({isLogin}) => (isLogin ? 0 : '0px auto'),
      maxWidth: ({isLogin}) => (isLogin ? width : width - 100),
      [theme.breakpoints.up('md')]: {
        margin: '0px auto',
      },
    },
  }));

  const classes = useStyles({isLogin: !auth.authenticated});
  return <div className={classes.container}>{children}</div>;
}

export function Routes() {
  return (
    <>
      <Wrapper>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/createPassword" component={CreatePassword} />
          <Route exact path="/tokenExpired" component={TokenExpired} />
          <Route
            exact
            path="/forgotPassword"
            component={() => <CreatePassword label="Forgot Password" />}
          />
          <Route
            exact
            path="/changePassword"
            component={() => (
              <CreatePassword
                label="Change Password"
                message="You have not changed your password in the last 90 days. Please change your password below to proceed."
              />
            )}
          />
          <Route exact path="/resetPassword" component={ResetPassword} />
          <Route exact path="/test" component={Test} />
          <PrivateRoute
            exact
            path="/"
            component={() => <Redirect to="/home" />}
          />
          <PrivateRoute exact path="/home" component={Homepage} />

          <PrivateRoute
            path="/logbook-builder"
            exact
            component={LogbookBuilder}
          />
          <PrivateRoute exact path="/inventory" component={Inventory} />
          <PrivateRoute exact path="/user-roles" component={UserRoles} />

          <PrivateRoute exact path="/admin/reload" component={AdminReload} />
          <PrivateRoute
            exact
            path="/admin/projections"
            component={AdminProjectionsRecreate}
          />

          <PrivateRoute exact path="/theme" component={ThemeSpecifics} />

          <PrivateRoute path="*" component={Page404} />
        </Switch>
      </Wrapper>
    </>
  );
}
