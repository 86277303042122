import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {Formik} from 'formik';
import {FTextField, useAlertContext} from '@deckmans/web-shared';
import {useMutation} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {UpdateOptionDialogCheckOptionMutation} from './__generated__/UpdateOptionDialogCheckOptionMutation.graphql';
import {UpdateOptionDialogMutation} from './__generated__/UpdateOptionDialogMutation.graphql';
import * as yup from 'yup';
interface Props {
  open: boolean;
  setOpen: () => void;
  decisionId: string;
  label: string;
  parentId: string | null;
  optionId: string;
}

const validationSchema = yup.object().shape({
  activity: yup.string().required('This field is required'),
});

export function UpdateOptionDialog({
  open,
  setOpen,
  decisionId,
  label,
  parentId,
  optionId,
}: Props) {
  const {alert} = useAlertContext();

  const [updateDecision] = useMutation<UpdateOptionDialogMutation>(
    graphql`
      mutation UpdateOptionDialogMutation($input: DecisionOptionUpdateInput!) {
        decisionOptionUpdate(input: $input) {
          decisionOption {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {decisionOptionUpdate}) {
        if (decisionOptionUpdate) {
          const payload = store.getRootField('decisionOptionUpdate');
          const eqName = payload.getLinkedRecord('decisionOption');
          if (eqName) {
            const nameOfEq = eqName.getValue('name');
            const idOfEq = eqName.getValue('id');
            if (idOfEq) {
              const newEqName = store.get(idOfEq);
              newEqName?.setValue(nameOfEq, 'name');
            }
          }
        }
      },
    }
  );

  const [checkDecisionKey] = useMutation<UpdateOptionDialogCheckOptionMutation>(
    graphql`
      mutation UpdateOptionDialogCheckOptionMutation(
        $input: DecisionOptionCheckKeyInput!
      ) {
        decisionOptionCheckKey(input: $input) {
          exist
        }
      }
    `
  );

  async function checkDecKey(
    decisionKey: string,
    decisionId: string,
    activity: string,
    label: string,
    parentId: string | null,
    optionId: string
  ) {
    let keyExist = false;
    const count = 1;
    let decKey = decisionKey;
    try {
      const response = await checkDecisionKey({
        variables: {
          input: {
            key: decisionKey,
          },
        },
      });
      if (response) {
        if (response.decisionOptionCheckKey?.exist === true) {
          keyExist = true;
          decKey = decisionKey + count;
          checkDecKey(decKey, decisionId, activity, label, parentId, optionId);
        }
      } else {
        alert('Something went wrong', 'error');
      }
    } catch (error) {
      alert('Something went wrong', 'error');
    }

    if (keyExist === false) {
      try {
        const response = await updateDecision({
          variables: {
            input: {
              optionId,
              decisionId,
              freeText: false,
              key: decKey,
              label,
              name: activity,
              parentId: parentId,
            },
          },
        });
        if (!response) {
          alert('Something went wrong', 'error');
        }
      } catch (error) {
        alert('Something went wrong', 'error');
      }
    }
  }

  return (
    <Formik
      initialValues={{activity: ''}}
      validationSchema={validationSchema}
      onSubmit={async (values, {resetForm}) => {
        const decisionKey = values.activity.replace(/ /g, '_').toUpperCase();
        checkDecKey(
          decisionKey,
          decisionId,
          values.activity,
          label,
          parentId,
          optionId
        );
        setOpen();
        resetForm();
      }}
    >
      {({submitForm}) => (
        <Dialog
          open={open}
          onClose={() => {
            setOpen();
          }}
          fullWidth
        >
          <DialogTitle>
            <Typography>Update {label} Name</Typography>
          </DialogTitle>
          <DialogContent>
            <FTextField
              fullWidth
              field="activity"
              placeholder={label}
              label={label}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              fullWidth
              onClick={() => {
                submitForm();
              }}
            >
              Update
            </Button>
            <Button
              fullWidth
              onClick={() => {
                setOpen();
              }}
              variant="text"
              color="secondary"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
