import {useMutation} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {MutationConfig} from 'relay-hooks/lib/useMutation';
import {useUserCreateMutation as useUserCreateMutationType} from './__generated__/useUserCreateMutation.graphql';

const fragment = graphql`
  mutation useUserCreateMutation($input: UserCreateInput!) {
    userCreate(input: $input) {
      user {
        ...UserRolesTable_user @relay(mask: false)
      }
    }
  }
`;
export const useUserCreateMutation = (
  config?: MutationConfig<useUserCreateMutationType>
) => useMutation<useUserCreateMutationType>(fragment, config);
