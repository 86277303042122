import React from 'react';
import {Button} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import {useUpdateDecisionSequenceMutation} from '../mutations/UpdateDecisionSequenceMutation';
import {useAlertContext} from '@deckmans/web-shared';
import {OptionsInner} from '../LogbookBuilderHelper';

interface Props {
  decisionArray: OptionsInner[];
  decisionId: string;
  isEditing: boolean;
  initialSequenceIsNull: boolean;
  setIsEditing: (val: boolean) => void;
}

export function EditOrOrderSequence({
  decisionArray,
  decisionId,
  isEditing,
  initialSequenceIsNull,
  setIsEditing,
}: Props) {
  const {alert} = useAlertContext();
  const [updateDecisionSequence] = useUpdateDecisionSequenceMutation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  return (
    <>
      <Button
        variant="text"
        onClick={() => {
          if (isEditing) {
            setIsEditing(false);
          } else {
            if (initialSequenceIsNull) {
              decisionArray.forEach(async (item, index) => {
                try {
                  setIsSubmitting(true);

                  const response = await updateDecisionSequence({
                    variables: {
                      input: {
                        optionId: item.id,
                        decisionId,
                        sequence: index + 1,
                      },
                    },
                  });
                  if (!response) {
                    alert('Something went wrong', 'error');
                    setIsSubmitting(false);
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  setIsSubmitting(false);
                }
              });
              setTimeout(() => {
                setIsSubmitting(false);
                setIsEditing(true);
              }, 6000);
            } else {
              setIsEditing(true);
            }
          }
        }}
      >
        {isSubmitting ? (
          <CircularProgress size={16} />
        ) : isEditing ? (
          <CheckIcon />
        ) : (
          'Edit'
        )}
      </Button>
    </>
  );
}
