import {useMutation} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {useUserLockAccountMutation as useUserLockAccountMutationType} from './__generated__/useUserLockAccountMutation.graphql';
import {MutationConfig} from 'relay-hooks/lib/useMutation';

const fragment = graphql`
  mutation useUserLockAccountMutation($input: UserLockAccountInput!) {
    userLockAccount(input: $input) {
      user {
        ...UserRolesTable_user @relay(mask: false)
      }
    }
  }
`;
export const useUserLockAccountMutation = (
  config?: MutationConfig<useUserLockAccountMutationType>
) => useMutation<useUserLockAccountMutationType>(fragment, config);
