import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'relay-hooks';
import {UserInfo_user$key} from './__generated__/UserInfo_user.graphql';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Divider,
  Grid,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  header: {
    color: theme.palette.primary.main,
  },
}));
const fragment = graphql`
  fragment UserInfo_user on User {
    profile {
      email
    }
    username
    account {
      lastLogin
      lastPasswordChange
      locked
    }
  }
`;
interface Props {
  userKey: UserInfo_user$key;
}

export function UserInfo({userKey}: Props) {
  const classes = useStyles();
  const user = useFragment(fragment, userKey);
  const [open, setOpen] = React.useState(false);
  const past90days =
    user.account.lastPasswordChange &&
    moment().diff(moment(user.account.lastPasswordChange), 'days') > 90;
  return (
    <>
      <VisibilityIcon
        color={past90days ? 'error' : 'primary'}
        onClick={() => setOpen(true)}
      />
      <Modal
        open={open}
        className={classes.modal}
        onClose={() => setOpen(false)}
      >
        <Paper
          style={{
            maxWidth: 450,
            padding: 30,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                align="center"
                color="primary"
                className={classes.header}
              >
                Account Information:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={6}>
              <Typography variant="h6">Username</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" align="right">
                {user.username}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div style={{width: '100%', backgroundColor: 'grey'}} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Last Login</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" align="right">
                {user.account.lastLogin
                  ? moment(user.account.lastLogin).format('YYYY-MM-DD HH:mm A')
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Last Password Change</Typography>
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title={
                  past90days
                    ? 'This password has not changed in the last 90 days'
                    : ''
                }
              >
                <Typography
                  variant="body1"
                  align="right"
                  color={past90days ? 'error' : undefined}
                >
                  {user.account.lastPasswordChange
                    ? moment(user.account.lastPasswordChange).format(
                        'YYYY-MM-DD HH:mm A'
                      )
                    : '-'}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Locked</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" align="right">
                {user.account.locked ? 'Locked' : 'Not locked'}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
}
