import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {ConnectionConfig, usePagination} from 'relay-hooks';
import {useRelayPagination} from 'hooks/useRelayPagination';
import MaterialTable from 'material-table';
import {makeStyles, Paper, TablePagination, TextField} from '@material-ui/core';
import {useMutation} from 'relay-hooks';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import {ConnectionHandler, GraphQLTaggedNode} from 'relay-runtime';
import {useAlertContext} from '@deckmans/web-shared';
import {useWindowSize} from 'react-use';
import {InventoryTableTipplersDeleteMutation} from './__generated__/InventoryTableTipplersDeleteMutation.graphql';
import {InventoryTableTipplersAddMutation} from './__generated__/InventoryTableTipplersAddMutation.graphql';
import {InventoryTableTipplersUpdateMutation} from './__generated__/InventoryTableTipplersUpdateMutation.graphql';
import {InventoryTableTipplers_tippler$key} from './__generated__/InventoryTableTipplers_tippler.graphql';

const useStyles = makeStyles(() => ({
  textFieldFont: {
    fontSize: '13px',
  },
}));

const fragment = graphql`
  fragment InventoryTableTipplers_tippler on Viewer {
    tipplers(first: $count, after: $cursor)
      @connection(key: "Viewer_tipplers") {
      edges {
        node {
          id
          name
        }
      }
      total
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

interface Props {
  query: GraphQLTaggedNode;
  tipplerKey: InventoryTableTipplers_tippler$key;
}

export function InventoryTableTipplers({query, tipplerKey}: Props) {
  const classes = useStyles();
  const {alert} = useAlertContext();
  const {height} = useWindowSize();
  const [deleteTippler] = useMutation<InventoryTableTipplersDeleteMutation>(
    graphql`
      mutation InventoryTableTipplersDeleteMutation(
        $input: TipplerDeleteInput!
      ) {
        tipplerDelete(input: $input) {
          id
        }
      }
    `,
    {
      updater(store, {tipplerDelete}) {
        if (tipplerDelete) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const tipplers = ConnectionHandler.getConnection(
              viewer,
              'Viewer_tipplers'
            );
            if (tipplers) {
              ConnectionHandler.deleteNode(tipplers, tipplerDelete.id);
            }
          }
        }
      },
    }
  );

  const [addTippler] = useMutation<InventoryTableTipplersAddMutation>(
    graphql`
      mutation InventoryTableTipplersAddMutation($input: TipplerCreateInput!) {
        tipplerCreate(input: $input) {
          tippler {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {tipplerCreate}) {
        if (tipplerCreate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const tipplers = ConnectionHandler.getConnection(
              viewer,
              'Viewer_tipplers'
            );
            if (tipplers) {
              const tippler = store.get(tipplerCreate.tippler.id);

              if (tippler) {
                const edge = ConnectionHandler.createEdge(
                  store,
                  tipplers,
                  tippler,
                  'TipplerEdge'
                );
                ConnectionHandler.insertEdgeAfter(tipplers, edge);
              }
            }
          }
        }
      },
    }
  );

  const [updateTippler] = useMutation<InventoryTableTipplersUpdateMutation>(
    graphql`
      mutation InventoryTableTipplersUpdateMutation(
        $input: TipplerUpdateInput!
      ) {
        tipplerUpdate(input: $input) {
          tippler {
            id
            name
          }
        }
      }
    `,
    {
      updater(store, {tipplerUpdate}) {
        if (tipplerUpdate) {
          const viewer = store.getRoot().getLinkedRecord('viewer');
          if (viewer) {
            const tipplers = ConnectionHandler.getConnection(
              viewer,
              'Viewer_tipplers'
            );
            if (tipplers) {
              const tippler = store.get(tipplerUpdate.tippler.id);
              if (tippler) {
                ConnectionHandler.createEdge(
                  store,
                  tipplers,
                  tippler,
                  'TipplerTrainEdge'
                );
              }
            }
          }
        }
      },
    }
  );

  const connectionConfig: ConnectionConfig = {
    getVariables(_props, {count, cursor}, _fv) {
      return {
        count,
        cursor,
      };
    },
    query,
  };

  const [tippler, pf] = usePagination(fragment, tipplerKey);

  const {page, pagedData, setPage} = useRelayPagination(
    tippler.tipplers.total ?? 0,
    10,
    pf,
    connectionConfig,
    tippler.tipplers
  );

  const tipplerTableData = React.useMemo(() => {
    const tableData: {
      id: string;
      name: string;
    }[] = [];

    pagedData.forEach((tippler) => {
      if (tippler) {
        tableData.push({
          id: tippler.id,
          name: tippler.name,
        });
      }
    });
    return tableData;
  }, [pagedData]);

  return (
    <>
      <MaterialTable
        data={tipplerTableData}
        options={{
          search: false,
          pageSize: 10,
          headerStyle: {position: 'sticky', top: 0},
          maxBodyHeight: height - 300,
          showTitle: false,
        }}
        columns={[
          {
            title: 'Name',
            field: 'name',
            validate: function validate(rowData) {
              if (!rowData.name) {
                return false;
              } else {
                if (rowData.name.trim() === '') {
                  return false;
                } else {
                  return true;
                }
              }
            },
            editComponent: function editComp(tableData) {
              const {columnDef, rowData, onRowDataChange, ...props} = tableData;
              return (
                <TextField
                  {...props}
                  value={tableData.rowData.name || ''}
                  inputProps={{autoFocus: true}}
                  placeholder="Name"
                  onChange={(e) => props.onChange(e.target.value)}
                  InputProps={{className: classes.textFieldFont}}
                />
              );
            },
          },
        ]}
        icons={{
          Add: React.forwardRef(function addCircle(props, ref) {
            return (
              <AddCircleIcon
                color="secondary"
                {...props}
                ref={ref}
                onClick={() => {}}
              />
            );
          }),

          Edit: React.forwardRef(function editCircle(props, ref) {
            return <EditRoundedIcon color="primary" {...props} ref={ref} />;
          }),

          Delete: React.forwardRef(function deleteCircle(props, ref) {
            return <DeleteRoundedIcon color="primary" {...props} ref={ref} />;
          }),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await addTippler({
                    variables: {
                      input: {
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowUpdate: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const response = await updateTippler({
                    variables: {
                      input: {
                        id: newData.id,
                        name: newData.name,
                      },
                    },
                  });
                  if (response) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                try {
                  const deleteResponse = deleteTippler({
                    variables: {input: {id: oldData.id}},
                  });
                  if (deleteResponse) {
                    resolve();
                  } else {
                    alert('Something went wrong', 'error');
                    reject();
                  }
                } catch (error) {
                  alert('Something went wrong', 'error');
                  reject();
                }
              }, 1000);
            }),
        }}
        components={{
          Pagination: function paginationComp(props) {
            return (
              <TablePagination
                {...props}
                rowsPerPageOptions={[]}
                rowsPerPage={10}
                count={tippler.tipplers.total ?? 0}
                page={page}
                onChangePage={(_e, page) => {
                  setPage(page);
                }}
              />
            );
          },
          Container: function containerComp(props) {
            return <Paper {...props} elevation={0} />;
          },
        }}
      />
    </>
  );
}
