import React from 'react';
import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  makeStyles,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
  children?: JSX.Element;
  name: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    boxShadow: 'none',
    width: '100%',
  },
  expansionSummaryBorder: {
    borderColor: theme.palette.divider,
  },
}));

const IconLeftExpansionPanelSummary = withStyles({
  expandIcon: {
    order: -1,
  },
})(ExpansionPanelSummary);

export function ExpansionRow({children, name}: Props) {
  const classes = useStyles();
  return (
    <>
      <ExpansionPanel className={classes.container}>
        <Box borderBottom={1} className={classes.expansionSummaryBorder}>
          <IconLeftExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            IconButtonProps={{edge: 'start'}}
          >
            <Typography>{name}</Typography>
          </IconLeftExpansionPanelSummary>
        </Box>
        <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
}
