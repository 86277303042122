import graphql from 'babel-plugin-relay/macro';
import {useMutation} from 'relay-hooks';
import {UpdateDecisionSequenceMutation} from './__generated__/UpdateDecisionSequenceMutation.graphql';
const mutation = graphql`
  mutation UpdateDecisionSequenceMutation(
    $input: DecisionOptionUpdateSequenceInput!
  ) {
    decisionOptionUpdateSequence(input: $input) {
      decisionOption {
        id
        name
        sequence
      }
    }
  }
`;
export const useUpdateDecisionSequenceMutation = () =>
  useMutation<UpdateDecisionSequenceMutation>(mutation);
